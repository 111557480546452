<template>
  <div>
    <h3 class="center">Ведомость начислений/выплат</h3>
    <div class="heapR">
      <div class="flexR" style="align-items: center">
        <DatePeriodSelector
          label="Период"
          labelPosition="left"
          :dateIn="null"
          @change="setDates($event)"
        />
        <button ref="calculateButton" v-on:click.prevent="Calculate()" class="btn">
          ПЕРЕСЧИТАТЬ НАЧИСЛЕНИЯ
        </button>
      </div>
      <br />
      <div class="flexR" style="aling-items: center; justify-content: space-between">
        <div class="margins1">
          <StatusSelector
            label="Критерий отбора"
            labelPosition="left"
            @change="status = $event"
          />
        </div>
        <div>
          <div class="flexR right">
            <input type="checkbox" v-model="columnSetup" />
            &nbsp;Настр.столбц.
          </div>
        </div>
      </div>

      <div v-show="columnSetup" class="center">
        <br />
        <div v-for="(x, i) in columnList" :key="i" class="flexR margins1">
          <input type="checkbox" v-model="columnSelected" :value="x" />
          <div class="vertical-list02">&nbsp;{{ columnListCaption[i] }}</div>
        </div>
      </div>
    </div>

    <div v-if="loading">
      <Loader />
    </div>

    <div v-else>
      <div class="reminder smalltext">работает сортировка по столбцам</div>
      <table class="t_table">
        <tr class="noselect"></tr>
        <tr>
          <th>№</th>
          <th
            @click="sort('nickname', data, $refs, oFlag)"
            ref="nickname_sort"
            :class="{ pointer: true, hide: !columnSelected.includes('nickname') }"
          >
            Ник
          </th>
          <th
            @click="sort('surname', data, $refs, oFlag)"
            ref="surname_sort"
            :class="{ pointer: true, hide: !columnSelected.includes('surname') }"
          >
            ФИ
          </th>
          <th
            @click="sort('job_name', data, $refs, oFlag)"
            ref="job_name_sort"
            :class="{ pointer: true, hide: !columnSelected.includes('job_name') }"
          >
            Должность
          </th>
          <th
            @click="sort('job_extra', data, $refs, oFlag)"
            ref="job_extra_sort"
            :class="{ pointer: true, hide: !columnSelected.includes('job_extra') }"
          >
            Должность доп.
          </th>
          <th
            @click="sort('date_hire', data, $refs, oFlag)"
            ref="date_hire_sort"
            :class="{ pointer: true, hide: !columnSelected.includes('date_hire') }"
          >
            Дата приёма
          </th>
          <th @click="sort('bank', data, $refs, oFlag)" ref="bank_sort" class="pointer">
            Банк
          </th>
          <th @click="sort('saldo', data, $refs, oFlag)" ref="saldo_sort" class="pointer">
            Сальдо
          </th>
          <th
            @click="sort('pay_amount', data, $refs, oFlag)"
            ref="pay_amount_sort"
            class="pointer"
          >
            К выплате
          </th>
          <th
            @click="sort('pay_amount_add', data, $refs, oFlag)"
            ref="pay_amount_add_sort"
            class="pointer"
          >
            К выплате доп.
          </th>
        </tr>
        <!-- data rows -->
        <tr class="user" v-for="(row, y) in filter(data)" :key="row.id" :ref="getRef(row.id)">
          <td>{{ y + 1 }}</td>
          <td
            :class="{ user: true, L: true, hide: !columnSelected.includes('nickname') }"
            @click="clickHandler($event, row.id)"
          >
            {{ row.nickname }}
          </td>
          <td
            :class="{ user: true, L: true, hide: !columnSelected.includes('surname') }"
            @click="clickHandler($event, row.id)"
          >
            {{ row.surname }} {{ row.name }}
          </td>
          <td :class="{ user: true, L: true, hide: !columnSelected.includes('job_name') }">
            {{ row.job_name }}
          </td>
          <td :class="{ user: true, L: true, hide: !columnSelected.includes('job_extra') }">
            {{ row.job_extra }}
          </td>
          <td :class="{ hide: !columnSelected.includes('date_hire') }">
            {{ dateFormatVarious(row.date_hire) }}
          </td>
          <td>{{ row.bank }}</td>
          <td class="R">{{ numFormat(row.saldo) }}</td>
          <td class="R pointer crutch" @click="crutchInput(row.id, row.pay_amount, 'paam')">
            <b>{{ numFormat(row.pay_amount) }}</b>
          </td>
          <td
            class="R pointer crutch"
            @click="crutchInput(row.id, row.pay_amount_add, 'paamad')"
          >
            <b>{{ numFormat(row.pay_amount_add) }}</b>
          </td>
        </tr>
        <template v-if="filter(data).length == 0">
          <tr>
            <td colspan="300" class="center">нет данных по выбранным параметрам</td>
          </tr>
        </template>
        <template v-else>
          <tr v-for="(el, i) in sumByBank()" :key="el[i]">
            <td :colspan="columnSelected.length + 1" class="white"></td>
            <td class="white">{{ el.bank }}</td>
            <td class="white"></td>
            <td class="R white">{{ numFormat(el.sum_paam) }}</td>
            <td class="R white">{{ numFormat(el.sum_paamad) }}</td>
          </tr>
          <tr>
            <td :colspan="columnSelected.length + 1" class="white"></td>
            <td class="white"><b>Итого</b></td>
            <td class="white"></td>
            <td class="R white">
              <b>{{ numFormat(pay_amountTotal("pay_amount")) }}</b>
            </td>
            <td class="R white">
              <b>{{ numFormat(pay_amountTotal("pay_amount_add")) }}</b>
            </td>
          </tr>
        </template>
      </table>
    </div>

    <MoneyWindow
      @hide="windowMoneyClose($event)"
      :showFlag="showFlag"
      :personIn="person"
      :dateLowest="dateLowest"
    />
  </div>
</template>

<style scoped>
.white {
  background-color: white;
}

.hide {
  display: none;
}

tr.user:hover td {
  /* cursor: pointer; */
  background-color: antiquewhite;
}

td.user {
  cursor: pointer;
}
td.crutch:hover {
  cursor: pointer;
  background-color: antiquewhite;
}
tr.calculating td {
  background-color: antiquewhite;
}
td {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.num {
  text-align: right;
}
td.R {
  text-align: right;
}
td.L {
  text-align: left;
}
</style>
<script>
import Loader from "@/components/Loader.vue";
import DatePeriodSelector from "@/components/DatePeriodSelector.vue";
import StatusSelector from "@/components/StatusSelector.vue";
import { request } from "@/components-js/requestSrv";
import { decorateField } from "@/components-js/decorateField.js";
import MoneyWindow from "@/components/MoneyWindow.vue";
import { sortCol } from "@/components-js/sortCol.js";
import { dateFormatJS, dateFormatVarious } from "@/components-js/dateFormat";
import { numFormat } from "@/components-js/numberFormat";
import { ACCRUAL_LIST } from "@/config/settings";

import { lS } from "@/components-js/localStorage";

const loStorage = new lS();

export default {
  components: { Loader, DatePeriodSelector, StatusSelector, MoneyWindow },
  data() {
    return {
      loading: false,
      data: [],
      oFlag: 1,
      person: null,
      showFlag: false,
      // all columns to be shown
      columnList: [],
      // all columns captions
      columnListCaption: [],
      // columns selected to show
      columnSelected: [],
      // selection list show/hide
      columnSetup: false,
      // selected in filter
      status: String,

      // lowest date
      dateLowest: "2000-01-01",

      date_begin: null,
      date_end: null,
      date_report: null,
      accrual_period: null,
    };
  },

  async mounted() {
    this.tableDataRetrieve();
  },

  watch: {
    columnSelected: function () {
      loStorage.saveObject(this.$route.path, this.columnSelected);
    },
  },

  methods: {
    pay_amountTotal(field_name) {
      let res = 0;
      this.data.forEach((el) => {
        if (el.status == this.status) res += parseInt(el[field_name]);
      });
      return res;
    },

    sumByBank() {
      const perBank = [];

      this.data.forEach((el) => {
        if (el.status == this.status) {
          let check = false;
          perBank.forEach((b) => {
            if (b.bank == el.bank) check = true;
          });

          if (!check) {
            perBank.push({ bank: el.bank, sum_paam: 0, sum_paamad: 0 });
          }

          perBank.forEach((b) => {
            if (b.bank == el.bank) {
              b.sum_paam += parseInt(el.pay_amount);
              b.sum_paamad += parseInt(el.pay_amount_add);
            }
          });
        }
      });
      return perBank;
    },

    async crutchInput(id_people, val, flag) {
      const newVal = prompt("Введите числовое значение", val);

      if (newVal == null) return;

      if (isNaN(newVal)) {
        alert("Неверное значение, не буду ЭТО записывать!");
        return;
      }

      const row = await request("/api/money/paamup", "POST", {
        id_people: id_people,
        val: newVal,
        flag: flag,
      });

      if (row.affectedRows) await this.singleDataRetreive(id_people);
    },

    setDates(o) {
      this.date_begin = o.date_begin;
      this.date_end = o.date_end;
      this.date_report = o.date_report;
      this.accrual_period = o.accrual_period;
    },

    numFormat(n) {
      return numFormat(n);
    },

    dateFormatJS(d) {
      return dateFormatJS(d);
    },

    dateFormatVarious(d) {
      return dateFormatVarious(d);
    },
    getRef(id) {
      return "ref_" + id;
    },

    decorateField(field, value) {
      return decorateField(field, value);
    },

    clickHandler(ev, id) {
      if (ev.target.tagName != "A" && ev.target.tagName != "IMG") this.windowMoneyOpen(id);
    },

    async tableDataRetrieve() {
      this.loading = true;
      this.data = await request("/api/money/peoplelist", "POST", {
        date_today: dateFormatJS(new Date()),
      });

      this.columnList = [];
      this.columnList.push("nickname");
      this.columnListCaption.push("Ник");
      this.columnList.push("surname");
      this.columnListCaption.push("ФИ");
      this.columnList.push("job_name");
      this.columnListCaption.push("Должность");
      this.columnList.push("job_extra");
      this.columnListCaption.push("Должность Доп.");
      this.columnList.push("date_hire");
      this.columnListCaption.push("Дата приёма");

      // this.columnList = this.data.slice(0, 1)[0];
      // set selectable columns
      const storedList = loStorage.getObject(this.$route.path);
      // const storedList = null;
      // console.log("storedList", storedList);
      if (storedList) {
        storedList.forEach((el) => {
          if (this.columnList.includes(el)) this.columnSelected.push(el);
        });
      } else {
        this.columnSelected.push("nickname");
        this.columnSelected.push("surname");
        this.columnSelected.push("job_name");
        this.columnSelected.push("job_extra");
        this.columnSelected.push("date_hire");
      }

      this.loading = false;
    },

    filter(data) {
      return data.filter((i) => i.status == this.status);
    },

    sort(col, arr, refs, oFlag) {
      this.oFlag = sortCol(col, arr, refs, oFlag);
    },

    windowMoneyOpen(id) {
      if (!id) return;

      const pos = this.data.findIndex((i) => i.id == id);
      this.person = this.data[pos];

      // show window at least
      this.showFlag = true;
    },

    async windowMoneyClose(evt) {
      this.showFlag = false;
      if (evt.dataChangeFlag) await this.singleDataRetreive(evt.id_people);
    },

    async Calculate() {
      if (!confirm("Пересчитать начисления за смены?")) return;
      this.$refs.calculateButton.disabled = true;
      for (const el of this.filter(this.data)) {
        this.$refs[this.getRef(el.id)][0].classList.add("calculating");
        await this.calculateSinglePerson(el.id);
        await this.singleDataRetreive(el.id);
        this.$refs[this.getRef(el.id)][0].classList.remove("calculating");
      }
      this.$refs.calculateButton.disabled = false;
    },

    async calculateSinglePerson(id_people) {
      // clear previous auto accrued and calculate'em again
      await request("/api/money/clearperiod", "DELETE", {
        accrual_list: ACCRUAL_LIST[this.accrual_period],
        id_people: id_people,
        date_report: this.date_report,
      });
      // console.log(id_people + ": rows deleted " + res.affectedRows);
      await request("/api/money/accrualperiod", "POST", {
        accrual_list: ACCRUAL_LIST[this.accrual_period],
        id_people: id_people,
        date_report: this.date_report,
        date_begin: this.date_begin,
        date_end: this.date_end,
      });
    },

    async singleDataRetreive(id_people) {
      const row = await request("/api/money/peoplesingle", "POST", {
        id_people: id_people,
        date_today: dateFormatJS(new Date()),
      });
      // the same user :)
      if (row[0].id == id_people) {
        const i = this.data.findIndex((el) => el.id == id_people);
        if (i != -1) {
          this.data[i].saldo = row[0].saldo;
          this.data[i].pay_amount = row[0].pay_amount;
          this.data[i].pay_amount_add = row[0].pay_amount_add;
        }
      }
    },
  },
};
</script>
