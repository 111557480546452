<template>
  <div v-if="showFlag">
    <section class="modalTurn">
      <div class="modalTurn-content widthfit">
        <div class="modalWin">
          <h3 v-if="person">Движение денег, {{ personIn.nickname }}</h3>
          <div v-if="person" class="grey">
            {{ personIn.surname }} {{ personIn.name }} {{ personIn.patronymic }}
          </div>
          <hr />
          <div class="err">{{ errMessage }}&nbsp;</div>
          <h3 v-if="!person">Ошибка</h3>
          <div class="heap heapR">
            <div class="heap-block">
              <DateSelector label="Дата нач." :val="date_begin" @change="date_begin = $event" />
            </div>
            <div class="heap-block">
              <DateSelector label="Дата кон." :val="date_end" @change="date_end = $event" />
            </div>
          </div>

          <table class="local">
            <tr v-if="!loading">
              <td colspan="300" style="padding: 0 0 0.5rem 0"><hr /></td>
            </tr>

            <tr>
              <td>Период ДО</td>
              <td class="R">{{ numFormat(balancePrev[0].accruedTotal) }}</td>
              <td class="R">{{ numFormat(balancePrev[0].paidTotal) }}</td>
              <td class="L">Баланс на начало периода</td>
              <td>{{ numFormat(balancePrev[0].accruedTotal - balancePrev[0].paidTotal) }}</td>
            </tr>

            <tr v-if="!loading">
              <td colspan="300" style="padding: 0.5rem 0 0 0"><hr /></td>
            </tr>

            <tr>
              <th>Дата операции</th>
              <th>Начислено</th>
              <th>Выплачено</th>
              <th>Пояснение</th>
              <th>Действие</th>
            </tr>
            <tr
              v-for="el in data"
              :key="el.id"
              :class="{
                row: true,
                accrued_auto: el.accrued && el.type.includes('auto'),
                accrued_manual: el.accrued && el.type == 'manual',
              }"
              @click.prevent="rowSelect(el.id)"
              :ref="rowPrefix + el.id"
            >
              <td>{{ dateFormatVarious(el.date, 2) }}</td>
              <td class="R">
                <div :class="{ avg_sal: el.avg_sal_flag }">{{ numFormat(el.accrued) }}</div>
              </td>
              <td class="R">{{ numFormat(el.paid) }}</td>
              <td class="L">{{ commentDecorate(el.comment, el.date_begin, el.date_end) }}</td>
              <td class="R" @click.stop="Delete(el.id)">
                <div class="lnkDel" style="text-decoration: none">
                  &nbsp;&nbsp;Удалить&nbsp;&nbsp;
                </div>
              </td>
            </tr>
            <tr v-if="loading"><td colspan="300">зогруска...</td></tr>
            <tr v-if="!loading && !data.length">
              <td colspan="300">нет данных</td>
            </tr>

            <tr v-if="!loading">
              <td colspan="300" style="padding: 0rem 0rem 0.5rem 0rem"><hr /></td>
            </tr>

            <tr>
              <td>Итог за период</td>
              <td class="R">{{ numFormat(accruedTotal) }}</td>
              <td class="R">{{ numFormat(paidTotal) }}</td>
              <td class="R">Баланс за период</td>
              <td>
                {{
                  numFormat(balanceAccruedPaid) ? numFormat(balanceAccruedPaid) : "сошёлся :)"
                }}
              </td>
            </tr>

            <tr v-if="!loading">
              <td colspan="300" style="padding: 0.5rem 0"><hr /></td>
            </tr>

            <tr v-if="!loading">
              <td><input type="date" v-model="date" ref="date" /></td>
              <td>
                <input type="number" v-model.number="accrued" class="num" ref="accrued" />
              </td>
              <td>
                <input type="number" v-model.number="paid" class="num" ref="paid" />
              </td>
              <td colspan="2" class="flex">
                <div class="group">
                  <input type="checkbox" v-model="avg_sal_flag" />
                  &nbsp;УчСрЗП
                </div>
                <textarea
                  v-model.trim="comment"
                  placeholder="Комментарий обязателен"
                  ref="comment"
                  style="width: 100%"
                />
              </td>
            </tr>

            <tr>
              <td colspan="300" style="padding: 0.5rem 0"><hr /></td>
            </tr>

            <tr>
              <td>Баланс итоговый</td>
              <td class="R">
                {{ numFormat(parseInt(balancePrev[0].accruedTotal) + parseInt(accruedTotal)) }}
              </td>
              <td class="R">
                {{ numFormat(parseInt(balancePrev[0].paidTotal) + parseInt(paidTotal)) }}
              </td>
              <td></td>
              <td>
                {{
                  numFormat(
                    parseInt(balancePrev[0].accruedTotal) +
                      parseInt(accruedTotal) -
                      parseInt(balancePrev[0].paidTotal) -
                      parseInt(paidTotal)
                  )
                }}
              </td>
            </tr>
          </table>

          <div class="col-revers">
            <button
              v-if="rowSelected"
              ref="insertButton"
              v-on:click.prevent="Update()"
              class="btn"
            >
              Обновить
            </button>
            <button v-else ref="insertButton" v-on:click.prevent="Insert()" class="btn">
              Добавить
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button v-if="rowSelected" v-on:click.prevent="Cancel()" class="btn cancel">
              Отмена
            </button>
            <button v-else v-on:click.prevent="windowClose()" class="btn cancel">
              Закрыть
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style scoped>
.avg_sal {
  font-weight: bold;
}

th {
  background-color: #434343;
  color: white;
  border: 0px;
}

.local {
  border-spacing: 0px;
}

.group {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-right: 1rem;
  border: solid 1px grey;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: row;
}

td {
  padding-right: 0.3rem;
  padding-left: 0.3rem;
}

tr.accrued_auto td {
  background-color: #cccccc;
}

tr.accrued_manual td {
  background-color: #d9ead3;
}

div {
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: middle;
}
.col-revers {
  display: flex;
  flex-direction: row-reverse;
}
td.R {
  text-align: right;
}
td.L {
  text-align: left;
}
table {
  border-spacing: 0.4rem;
}
.middle {
  vertical-align: middle;
}
.widthfit {
  width: fit-content;
}
.num {
  width: 5rem;
  text-align: right;
}
.lnkDel {
  cursor: pointer;
}
.lnkDel:hover {
  color: red;
  background-color: none;
}
.lnkUpd {
  cursor: pointer;
}
.lnkUpd:hover {
  color: #406d96;
  background-color: none;
}
tr.row:hover td {
  cursor: pointer;
  background-color: beige;
}
tr.rowSelected td {
  background-color: beige;
}
</style>
<script>
import DateSelector from "@/components/DateSelector.vue";
import { dateFormatJS, dateFormatVarious } from "@/components-js/dateFormat";
import { numFormat } from "@/components-js/numberFormat";
import { request } from "@/components-js/requestSrv";
import { PHOTO_ROUTE } from "../config/settings";
import { lS } from "@/components-js/localStorage";
const loStorage = new lS();

export default {
  name: "PeopleWindow",
  components: {
    DateSelector,
  },
  props: {
    showFlag: Boolean,
    personIn: Object,
    dateLowest: String,
  },

  data() {
    return {
      errMessage: null,
      balancePrev: [0],
      data: [],
      person: {},
      PHOTO_ROUTE: PHOTO_ROUTE,
      photo: String,
      date_begin: null,
      date_end: null,

      loading: false,

      date: null,
      accrued: null,
      paid: null,
      comment: null,
      avg_sal_flag: 0,

      dataChangeFlag: false,
      rowPrefix: "row",
      rowSelected: null,
    };
  },

  watch: {
    showFlag: async function (newValue) {
      if (!newValue) return;

      this.date = dateFormatJS(new Date());

      let tmp = loStorage.getObjectProp(this.$route.path + "window", "date_begin");
      if (tmp) this.date_begin = tmp;

      tmp = loStorage.getObjectProp(this.$route.path + "window", "date_end");
      if (tmp) this.date_end = tmp;

      if (!this.date_begin || !this.date_end) {
        this.date_begin = dateFormatJS(new Date(new Date().setDate(1)));
        this.date_end = dateFormatJS(new Date());
      }

      // window appeared
      if (newValue) document.addEventListener("keyup", this.keyPressHandler);
      else document.removeEventListener("keyup", this.keyPressHandler);

      await this.updateLocal(false);
    },

    accrued: function (newVal) {
      if (newVal === null) return;
      this.paid = null;
    },

    paid: function (newVal) {
      if (newVal === null) return;
      this.accrued = null;
    },

    date_begin: async function (newVal, oldVal) {
      // exclude window opening
      if (oldVal == null) return;
      loStorage.saveObject(this.$route.path + "window", {
        date_begin: this.date_begin,
        date_end: this.date_end,
      });
      this.loading = true;
      await this.tableDataRetreive();
      this.loading = false;
    },

    date_end: async function (newVal, oldVal) {
      // exclude window opening
      if (oldVal == null) return;
      loStorage.saveObject(this.$route.path + "window", {
        date_begin: this.date_begin,
        date_end: this.date_end,
      });
      this.loading = true;
      await this.tableDataRetreive();
      this.loading = false;
    },
  },

  computed: {
    accruedTotal: function () {
      let sum = 0;
      this.data.forEach((el) => (sum += el.accrued ? el.accrued : 0));
      return sum;
    },

    paidTotal: function () {
      let sum = 0;
      this.data.forEach((el) => (sum += el.paid ? el.paid : 0));
      return sum;
    },

    balanceAccruedPaid: function () {
      return this.accruedTotal - this.paidTotal;
    },
  },

  methods: {
    async tableDataRetreive() {
      this.balancePrev = await request("/api/money/saldoforperiod", "POST", {
        id_people: this.personIn.id,
        date_begin: this.dateLowest,
        date_end: dateFormatJS(
          new Date(new Date(this.date_begin).setDate(new Date(this.date_begin).getDate() - 1))
        ),
      });

      this.data = await request("/api/money/movementforperiod", "POST", {
        id_people: this.personIn.id,
        date_begin: this.date_begin,
        date_end: this.date_end,
      });
    },

    commentDecorate(comment, date_begin, date_end) {
      // if period is empty
      if (!date_begin || !date_end) return comment;
      // otherwise add it
      return (
        comment +
        " (" +
        this.dateFormatVarious(date_begin, 1) +
        " - " +
        this.dateFormatVarious(date_end, 1) +
        ")"
      );
    },

    numFormat(n) {
      return numFormat(n);
    },

    dateFormatVarious(d, w) {
      return dateFormatVarious(d, w);
    },

    dateFormatLocal(date) {
      const d = new Date(date);
      return (
        (d.getDate() < 10 ? "0" : "") +
        d.getDate() +
        "." +
        (d.getMonth() + 1 < 10 ? "0" : "") +
        (d.getMonth() + 1) +
        "." +
        d.getFullYear() +
        " (" +
        new Intl.DateTimeFormat("ru-RU", { weekday: "short" }).format(d) +
        ")"
      );
    },

    setType(ref, type) {
      this.$refs[ref].setAttribute("type", type);
    },

    keyPressHandler(evt) {
      if (evt.code == "Escape") this.windowClose();
    },

    checkData() {
      // date
      if (new Date(this.date) == "Invalid Date") {
        this.$refs.date.classList.add("warn-border");
        setTimeout(() => this.$refs.date.classList.remove("warn-border"), 300);
        return false;
      }

      // money
      if (!this.accrued && !this.paid) {
        this.$refs.accrued.classList.add("warn-border");
        this.$refs.paid.classList.add("warn-border");
        setTimeout(() => {
          this.$refs.accrued.classList.remove("warn-border");
          this.$refs.paid.classList.remove("warn-border");
        }, 300);
        return false;
      }

      // comment
      if (!this.comment || this.comment.length < 8) {
        this.$refs.comment.classList.add("warn-border");
        setTimeout(() => this.$refs.comment.classList.remove("warn-border"), 300);
        this.errMessage = `Комментарий длиной не менее ${parseInt(
          Math.random() * 64 + 32
        )} символов.`;
        setTimeout(() => (this.errMessage = null), 2000);
        return false;
      }

      // check for avg_sal_flag :)
      if (this.accrued == null) this.avg_sal_flag = 0;

      return true;
    },

    async Update() {
      if (!this.checkData()) return;

      const res = await request("/api/money/movementupdate", "POST", {
        date: this.date,
        accrued: this.accrued,
        paid: this.paid,
        comment: this.comment,
        avg_sal_flag: this.avg_sal_flag,
        id: this.rowSelected,
        id_people: this.personIn.id,
      });

      if (res.affectedRows == 1) {
        this.clearInput();
        this.clearSelection();
        this.updateLocal(true);
      } else {
        this.errMessage = "Ошибка обновления данных";
        setTimeout(() => (this.errMessage = null), 2000);
      }
    },

    async Insert() {
      if (!this.checkData()) return;
      this.$refs.insertButton.disabled = true;
      const res = await request("/api/money/movementinsert", "POST", {
        date: this.date,
        id_people: this.personIn.id,
        accrued: this.accrued,
        paid: this.paid,
        type: "manual",
        comment: this.comment,
        avg_sal_flag: this.avg_sal_flag,
      });
      this.$refs.insertButton.disabled = false;
      if (res.affectedRows == 1 && res.insertId) {
        this.clearInput();
        this.clearSelection();
        this.updateLocal(true);
      }

      // const res = await request("/api/user/update", "POST", frmData, null, true);
      // if (res.affectedRows) this.windowClose();
      // else {
      //   this.$refs.applyButton.classList.add("warn");
      //   setTimeout(() => this.$refs.applyButton.classList.remove("warn"), 300);
      //   return;
      // }
    },

    clearSelection(id) {
      if (id && this.$refs[this.rowPrefix + id][0]) {
        this.$refs[this.rowPrefix + id][0].classList.remove("rowSelected");
        this.rowSelected = null;
      }

      // in case of null id
      if (!id && this.rowSelected && this.$refs[this.rowPrefix + this.rowSelected][0]) {
        this.$refs[this.rowPrefix + this.rowSelected][0].classList.remove("rowSelected");
        this.rowSelected = null;
      }
    },

    setSelection(id) {
      if (this.$refs[this.rowPrefix + id][0])
        this.$refs[this.rowPrefix + id][0].classList.add("rowSelected");
    },

    setInput(id) {
      this.clearInput;
      const p = this.data.findIndex((el) => id == el.id);

      this.date = this.data[p].date;
      this.comment = this.data[p].comment;
      this.avg_sal_flag = this.data[p].avg_sal_flag;
      if (this.data[p].accrued) this.accrued = this.data[p].accrued;
      else this.paid = this.data[p].paid;
    },

    clearInput() {
      this.date = dateFormatJS(new Date());
      this.accrued = null;
      this.paid = null;
      this.comment = null;
      this.avg_sal_flag = 0;
    },

    rowSelect(id) {
      if (this.rowSelected) this.clearSelection(this.rowSelected);

      this.rowSelected = id;
      this.setSelection(this.rowSelected);
      this.setInput(this.rowSelected);
    },

    async Delete(id) {
      if (!confirm("Удалить запись?")) return;
      let res = await request("/api/money/movementdelete", "DELETE", {
        id: id,
        id_people: this.personIn.id,
      });
      if (!res.affectedRows) {
        this.errMessage = "ошибка удаления";
        setTimeout(() => (this.errMessage = null), 1500);
      }
      this.clearInput(id);
      this.clearSelection();
      this.updateLocal(true);
    },

    async updateLocal(dataChangeFlag) {
      this.loading = true;
      this.tableDataRetreive();
      this.loading = false;
      this.dataChangeFlag = dataChangeFlag;
    },

    Cancel() {
      this.clearInput();
      this.clearSelection();
    },

    windowClose() {
      this.clearInput();
      this.clearSelection();
      this.data = [];
      this.$emit("hide", { dataChangeFlag: this.dataChangeFlag, id_people: this.personIn.id });
    },
  },
};
</script>
