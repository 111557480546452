<template>
  <div class="margins1">
    <div class="heapC">
      <h2>Привет, {{ this.$root.user.nickname }}!</h2>
      <div v-if="pointAdminList.length">
        <h3>Ты повелитель точек</h3>
        <div v-for="el in pointAdminList" :key="el">
          {{ el }}
        </div>
      </div>
      <h2>
        <template v-if="pathHidden">
          <span class="pointer" @click="followLink(pathHidden)">{{ title }}</span>
        </template>
        <template v-else>{{ title }}</template>
        {{
          new Intl.DateTimeFormat("ru-RU", {
            weekday: "long",
            day: "numeric",
            month: "long",
            year: "numeric",
          }).format(dateIn)
        }}
        <span v-if="pathRightSide" class="pointer noselect" @click="followLink(pathRightSide)">
          &nbsp;>>>
        </span>
      </h2>
    </div>
    <div v-if="loading">
      <Loader />
    </div>
    <transition name="fade">
      <div v-if="!loading">
        <BirthDay :date="dateIn" label="с Днём рождения!" labelPosition="left" />
        <template v-for="(point, i) in pointsAll">
          <div :key="i">
            <h3 class="margins2">{{ point.nickname }}</h3>
            <template v-for="(turn, j) in turnsAll">
              <div
                :key="j"
                v-if="point.id == turn.id_point"
                :class="{ margins3: true, bold: turn.active }"
              >
                {{ offsetApply(turn.hour_begin) }}&mdash;{{
                  offsetApply(turn.hour_end)
                }}&nbsp;&nbsp;{{ turn.nickname }} {{ turn.job_icon }}
              </div>
            </template>
          </div>
        </template>
      </div>
    </transition>
    <div>
      <img
        ref="whale"
        src="../assets/whale4.png"
        alt=""
        v-on:load="whaleOn()"
        class="whalehidden"
      />
    </div>
  </div>
</template>
<style scoped>
.fade-enter {
  margin-left: 32px;
  opacity: 0;
}
.fade-enter-active {
  transition: margin-left 0.5s, opacity 0.5s;
}
</style>
<script>
import Loader from "@/components/Loader.vue";
import BirthDay from "@/components/BirthDay.vue";
import { dateFormatJS } from "@/components-js/dateFormat";
import { request } from "@/components-js/requestSrv";
import { timeFormat, offsetApply } from "@/components-js/timeOffset";

export default {
  name: "DayTemplate",
  components: { Loader, BirthDay },
  props: {
    title: String,
    dateIn: Date,
    pathRightSide: String,
    pathHidden: String,
  },
  data() {
    return {
      loading: false,
      pointsAll: [],
      turnsAll: [],
      pointAdminList: [],
    };
  },

  async created() {
    this.loading = true;
    await this.tableDataRetrieve();
    this.loading = false;
  },

  methods: {
    offsetApply(h /*, l*/) {
      return offsetApply(h /*, l*/);
    },

    timeFormat(time, flag) {
      return timeFormat(time, flag);
    },

    followLink(href) {
      this.$router.push(href);
    },

    whaleOn() {
      // if (Math.random() * 4 > 3)
      //   setTimeout(() => (this.$refs.whale.classList = "whalemove"), 1000);
    },

    async tableDataRetrieve() {
      this.pointsAll = await request("/api/point/list_today");
      this.turnsAll = await request("/api/turn/list_all_for_date", "POST", {
        date: dateFormatJS(this.dateIn),
      });
      this.pointAdminList = await request("/api/user/adminpointlist", "POST", {
        id: this.$root.user.uid,
        data: "name",
      });
      // exclude empty points
      this.pointsAll = this.pointsAll.filter((p) => {
        for (let i in this.turnsAll) if (this.turnsAll[i].id_point == p.id) return true;
      });
    },
  },
};
</script>
