<template>
  <div class="noselect margins3" style="align-self: start">
    <div>
      <h3 class="pointer" @click="followLink('/chesstabledraft')">Черновик</h3>
      <h3 class="pointer" @click="followLink('/bariskahistory')">Путь бариски</h3>
      <h3 class="pointer" @click="followLink('/salesin')">Вгрузка чеков</h3>
      <h3 class="pointer" @click="followLink('/statsfunny')">Занимательная статистика</h3>
      <h3 class="pointer" @click="followLink('/moneymovementsimport')">
        Импорт начислений/выплат
      </h3>
      <h3 class="pointer" @click="followLink('/auto')">Автомат</h3>
      <h3 class="pointer" @click="followLink('/logs')">Журнал действий сотрудников</h3>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {
    followLink(href) {
      this.$router.push(href);
    },
  },
};
</script>
