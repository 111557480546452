<template>
  <div class="margins1">
    <h3 class="center">Вгрузка чеков</h3>
    <form ref="form" method="post" action="/api/upload/salesin" enctype="multipart/form-data">
      <input type="file" name="sales" />
      <br />
      <br />
      <input ref="buttonSend" type="button" value="Отправить" @click="fileSend()" />

      <div v-if="output">
        <br />
        <br />
        <div>Источник данных: {{ output.fileNameSrc }}</div>
        <div>Размер: {{ output.fileSize + " " + output.fileSizeUnit }}</div>
        <div>Строк прочитано: {{ output.fileLinesTotal }}</div>
        <div>Статус: {{ output.fileStatus ? "обработан" : "не обработан" }}</div>
        <div v-if="output.fileStatus">Внесено строк: {{ linesAffected }}</div>
        <br />
        <div v-if="output.fileStatus">
          <div v-for="(item, i) in output.pointStat" :key="i">
            <div>{{ i }}: {{ item }}</div>
          </div>
          <br />
        </div>
        <div v-if="timing">Время обработки: {{ Math.round(timing / 10) / 100 }} сек.</div>
      </div>
    </form>
  </div>
</template>

<script>
import { request } from "@/components-js/requestSrv";
export default {
  data() {
    return {
      output: null,
      timing: 0,
    };
  },

  computed: {
    linesAffected: function () {
      let sum = 0;
      for (const i in this.output.pointStat) sum += this.output.pointStat[i];
      return sum;
    },
  },

  methods: {
    followLink(href) {
      this.$router.push(href);
    },

    async fileSend() {
      this.$refs.buttonSend.disabled = true;
      this.output = null;
      let t1 = new Date().getTime();
      /*
            const resp = await fetch("/api/sales/salesin", {
              method: "POST",
              body: new FormData(this.$refs.form),
            });
      */

      const row = await request(
        "/api/sales/salesin",
        "POST",
        new FormData(this.$refs.form),
        null,
        true
      );

      this.$refs.buttonSend.disabled = false;

      if (row) {
        this.output = row;
        this.timing = new Date().getTime() - t1;
      }

      // console.log(row);

      // this.output = await resp.json();
      //   resp.json().then((response) => {
      //     this.$refs.buttonSend.disabled = false;
      //     this.output = response;
      //     this.timing = new Date().getTime() - t1;
      //   });
    },
  },
};
</script>
