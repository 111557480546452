<template>
  <div v-show="showFlag">
    <section class="modalTurn">
      <div class="modalTurn-content">
        <div class="modalWin">
          <h2>
            Заполнение черновика
            <br />
            cвободными сменами
          </h2>
          <h4>Период</h4>
          <div>{{ dateFormatHuman(date_begin) }} - {{ dateFormatHuman(date_end) }}</div>
          <br />
          <h4>Смены в текущем периоде черновика</h4>
          <div v-if="loading">
            <Loader />
          </div>
          <div v-else>
            <div>Свободных смен {{ turnsFreecnt }}</div>
            <div>Назначенных смен {{ turnsClosedcnt }}</div>
            <br />
            <div class="err" v-if="turnsFreecnt + turnsClosedcnt">
              Смены в черновике будут удалены, {{ turnsFreecnt + turnsClosedcnt }} шт.
            </div>
          </div>
          <br />

          &nbsp;&nbsp;&nbsp;&nbsp;
          <button v-on:click="windowClose(false)" class="btn cancel" v-if="!updateBegin">
            Отмена
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button ref="applyButton" v-on:click="Apply()" class="btn" v-if="!updateBegin">
            Заполнить
          </button>

          <div v-if="updateEnd && turnsDraftDeletedCnt">
            Удалено смен: {{ turnsDraftDeletedCnt }}
          </div>
          <div v-if="updateEnd && turnsDraftCreatedCnt">
            Создано смен: {{ turnsDraftCreatedCnt }}
          </div>

          <br />
          <button v-on:click="windowClose(true)" class="btn cancel" v-if="updateEnd">
            Закрыть
          </button>
        </div>
      </div>
    </section>
  </div>
</template>


<style scoped>
.grey {
  color: grey;
  text-decoration: underline;
}
</style>

<script>
import Loader from "@/components/Loader.vue";
import { dateFormatHuman } from "@/components-js/dateFormat";
import { request } from "@/components-js/requestSrv";

export default {
  name: "FillPeriodWindow",
  components: {
    Loader,
  },

  props: {
    showFlag: Boolean,
    date_begin: null,
    date_end: null,
    type: null,
    dataSource: "",
    pointList: [],
  },

  data() {
    return {
      errMessage: null,
      turnsFreecnt: 0,
      turnsDraftDeletedCnt: 0,
      turnsClosedcnt: 0,
      turnsDraftCreatedCnt: 0,

      loading: true,
      updateBegin: false,
      updateEnd: false,
    };
  },
  watch: {
    showFlag: async function (newVal) {
      if (newVal) {
        this.loading = true;
        this.updateBegin = false;
        this.updateEnd = false;
        await this.turnsFreeCount();
        await this.turnsClosedCount();
        this.loading = false;
      }
    },
  },

  methods: {
    // keyPressHandler(evt) {
    //   if (evt.code == "Escape") this.windowClose(0, null);
    //   if (evt.code == "Enter") this.Apply();
    // },
    async turnsFreeCount() {
      this.turnsFreecnt = await request("/api/turndraft/countbydatesbystatus", "POST", {
        date_begin: this.date_begin,
        date_end: this.date_end,
        status: "free",
        dataSource: this.dataSource,
        pointList: this.pointList,
      });
    },

    async turnsClosedCount() {
      this.turnsClosedcnt = await request("/api/turndraft/countbydatesbystatus", "POST", {
        date_begin: this.date_begin,
        date_end: this.date_end,
        status: "closed",
        dataSource: this.dataSource,
        pointList: this.pointList,
      });
    },

    dateFormatHuman(d, w) {
      return dateFormatHuman(d, w);
    },

    async Apply() {
      // disable button for prevent turn dupliction
      this.updateBegin = true;
      this.turnsDraftDeletedCnt = await request("/api/turndraft/deletebydates", "DELETE", {
        date_begin: this.date_begin,
        date_end: this.date_end,
        dataSource: this.dataSource,
        pointList: this.pointList,
      });

      this.turnsDraftCreatedCnt = await request("/api/turndraft/insertbulk", "POST", {
        date_begin: this.date_begin,
        date_end: this.date_end,
        dataSource: this.dataSource,
        pointList: this.pointList,
      });

      this.updateEnd = true;
    },

    windowClose(flag) {
      this.$emit("close", flag);
    },
  },
};
</script>
