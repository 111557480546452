const CHESSTABLE_BORDER_WIDTH = 1;
const COLORS_HIGHLIGHT = [
  "#afb194",
  "#bf7f3f",
  "#b363e2",
  "#b6b844",
  "#484acb",
  "#58bfb7",
  "#e93e52",
];
const COLORS_HIGHLIGHT_USAGE = [];
const TURN_OWN_COLOR = "#193958";

export { CHESSTABLE_BORDER_WIDTH, COLORS_HIGHLIGHT, COLORS_HIGHLIGHT_USAGE, TURN_OWN_COLOR };
