<template>
  <label>
    {{ label }}
    <select @change="$emit('change', $event.target.value)" ref="hour" v-model="valueLocal">
      <template v-for="el in HOURS">
        <option v-if="el" :value="el" :key="el">
          <!-- {{ el + parseInt(offset) == 24 ? "00" : el + parseInt(offset) }} -->
          {{ timeFormat(offsetApply(el + parseInt(offset)), "short") }}
        </option>
      </template>
    </select>
  </label>
</template>

<script>
import { HOURS } from "@/config/settings";
import { timeFormat, offsetApply } from "@/components-js/timeOffset";
export default {
  name: "HourSelector",
  props: { label: String, value: Number, offset: Number(0) },
  watch: {
    value: function (newVal) {
      this.valueLocal = newVal;
    },
  },
  data: function () {
    return {
      HOURS,
      valueLocal: null,
    };
  },
  methods: {
    offsetApply(h) {
      return offsetApply(h);
    },

    timeFormat(time, flag) {
      return timeFormat(time, flag);
    },
  },
};
</script>
