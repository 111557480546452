<template>
  <div id="app">
    <div id="nav" class="title noselect" v-show="links_flag">
      <template v-for="item in Links">
        <div :key="item.to" class="item">
          <router-link
            v-if="item.showFlag"
            :key="item.to"
            :to="item.to"
            :class="{ navlogout: item.to == '/logout' }"
          >
            {{ item.title }}
          </router-link>
        </div>
      </template>
    </div>
    <div style="padding-bottom: 3rem; width: 100%" class="wide">
      <router-view />
    </div>
  </div>
</template>

<script>
import { userDataGet } from "@/components-js/requestSrv";

export default {
  data: function () {
    return {
      links_flag: false,
      // first line-visible links, shown by router
      Links: [
        {
          to: "/today",
          title: "Сегодня",
          roles: ["user", "admin"],
          showFlag: false,
        },
        {
          to: "/chesstable",
          title: "Расписание",
          roles: ["user", "admin"],
          showFlag: false,
        },
        {
          to: "/personal",
          title: "Смены",
          roles: ["user"],
          showFlag: false,
        },
        {
          to: "/freeturns",
          title: "Свободные",
          roles: ["user"],
          showFlag: false,
        },
        { to: "/reports", title: "Отчёты", roles: ["admin"], showFlag: false },
        {
          to: "/reference",
          title: "Справочник",
          roles: ["admin"],
          showFlag: false,
        },
        { to: "/othersusr", title: "Другое", roles: ["user"], showFlag: true },
        // {
        //   to: "/peoplelist",
        //   title: "Люди",
        //   roles: ["user"],
        //   showFlag: false,
        // },
        {
          to: "/wiki",
          title: "Wiki",
          roles: ["user", "admin"],
          showFlag: false,
        },
        { to: "/othersadm", title: "Прочее", roles: ["admin"], showFlag: true },
        {
          to: "/logout",
          title: "Выход",
          roles: ["user", "admin"],
          showFlag: false,
        },
      ],
    };
  },

  async updated() {
    this.$root.user = await userDataGet();
    if (this.$route.meta.showMenu) {
      //const rolesUser = await rolesGet();
      for (let l of this.Links) {
        // const temp = x.roles.map((r) => rolesUser.includes(r));
        // x.showFlag = temp.includes(true);
        // hidden used for hide REPORTS
        // they demostrate using $router.push action
        if (l.hidden) continue;
        l.showFlag = l.roles.map((r) => this.$root.user.roles.includes(r)).includes(true);
      }
    }
    this.links_flag = this.$route.meta.showMenu;
  },
};
</script>
