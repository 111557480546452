const DEBUG = false;

const POINTS_SHORTLY = { 1: "Л", 2: "М", 3: "Б", 4: "Я", 5: "Ц", 6: "Т" };
const DAYTIME = { morning: "утро", evening: "вечер" };

// timeline in table
const HOUR_START = 7;
const HOUR_END = 24;
const HOUR_END_VISUAL = 23;
// for move behind midnight
const HOUR_OFFSET = 0;
/*
-- in case of adding time offset need to change existing turns by this offset value
update brew_turn set
   time_begin = date_add(time_begin, interval +/-HOUR_OFFSET hour)
  ,time_end = date_add(time_end, interval +/-HOUR_OFFSET hour);
*/

const HOURS = [];
for (let i = HOUR_START; i <= HOUR_END; i++) HOURS[i] = i;
const HOURS_VISUAL = [];
for (let i = HOUR_START; i <= HOUR_END_VISUAL; i++) HOURS_VISUAL[i] = i;

const HOURSADD = [0, 1, 2, 3, 4, 5, 6];

const MINUTES = [0, 15, 30, 45];

const DATE_MAX_INTERVAL = 60;

const ACCRUAL_PERIOD = ["I", "II"];

const ACCRUAL_LIST = {
  I: ["auto_turns", "auto_overload"],
  II: ["auto_turns", "auto_overload", "auto_nonsmoke"],
};

const GENDER_VALUE_FULL = ["f", "m", "n", "g", "l", "t"];
const GENDER_VALUE_HOMOPHOBIAN = ["f", "m", "n"];
const GENDER_CAPTION = { f: "жен", m: "муж", n: "не выбран", g: "гей", l: "лесб", t: "транс" };

const SMOKE_VALUE = ["smoke", "agreement", "none", "other"];
const SMOKE_CAPTION = {
  smoke: "курит",
  agreement: "соглашение (премия)",
  none: "нет",
  other: "другое",
};

const POINT_SALES = 13;
const POINT_SALES_K = 1.5;
const POINT_TIMEZONE = 4;

const BANK_VALUE = ["Альфа", "Сбер", "Тинёк", "Другое"];
const BANK_CAPTION = { Альфа: "Альфа", Сбер: "Сбер", Тинёк: "Тинёк", Другое: "Другое" };

const STATUS_VALUE = ["work", "fired", "pause", "workpause"];
const STATUS_CAPTION = {
  work: "в игре",
  fired: "уволен",
  pause: "на паузе",
  workpause: "в игре и на паузе",
};

const HISTORY_EVENT_VALUE = [
  "learn",
  "attestation",
  "evolution",
  "job_change",
  "achievement",
  "other",
];
const HISTORY_EVENT_CAPTION = {
  learn: "Обучение",
  attestation: "Аттестация",
  evolution: "Развитие",
  job_change: "Смена должности",
  achievement: "Ачивка",
  other: "Другое",
};

const ATTESTATION_VALUE = [
  "kassa",
  "tk",
  "alternate",
  "milk",
  "espresso",
  "barista",
  "grandbarista",
];
const ATTESTATION_CAPTION = {
  kassa: "Касса",
  tk: "ТК",
  alternate: "Альтернатива",
  milk: "Молоко",
  espresso: "Эспрессо",
  barista: "Бариста",
  grandbarista: "Гранд бариста",
};

const WEEKDAYS = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const WEEKDAYS_SHORTLY = {
  Monday: "Пн",
  Tuesday: "Вт",
  Wednesday: "Ср",
  Thursday: "Чт",
  Friday: "Пт",
  Saturday: "Сб",
  Sunday: "Вс",
};

const TIMEZONE_DEFAULT_HOUR = 4,
  TIMEZONE_DEFAULT_MIN = 0;

const TELEGRAM_URL_PREFIX = "https://t.me/";
const TELEGRAM_NICK_DEFAULT = "brewsell_coffee";

const PHOTO_ROUTE = "/photo/";

const EVENT_TYPE = {
  birth: "\u{1F382}", // 🎂
  anniversary_people: "\u{1F4C5}", // 📅
  anniversary_point: "\u{2B50}", // ⭐
};

export {
  POINTS_SHORTLY,
  DEBUG,
  HOURS,
  HOURS_VISUAL,
  HOURSADD,
  MINUTES,
  HOUR_START,
  HOUR_END,
  HOUR_OFFSET,
  DATE_MAX_INTERVAL,
  GENDER_VALUE_FULL,
  GENDER_VALUE_HOMOPHOBIAN,
  GENDER_CAPTION,
  SMOKE_VALUE,
  SMOKE_CAPTION,
  BANK_VALUE,
  BANK_CAPTION,
  STATUS_VALUE,
  STATUS_CAPTION,
  WEEKDAYS,
  WEEKDAYS_SHORTLY,
  TIMEZONE_DEFAULT_HOUR,
  TIMEZONE_DEFAULT_MIN,
  DAYTIME,
  TELEGRAM_URL_PREFIX,
  TELEGRAM_NICK_DEFAULT,
  PHOTO_ROUTE,
  EVENT_TYPE,
  HISTORY_EVENT_VALUE,
  HISTORY_EVENT_CAPTION,
  ATTESTATION_VALUE,
  ATTESTATION_CAPTION,
  ACCRUAL_PERIOD,
  ACCRUAL_LIST,
  POINT_SALES,
  POINT_SALES_K,
  POINT_TIMEZONE,
};
