<template>
  <div class="noselect margins3" style="align-self: start">
    <div>
      <h3 class="pointer" @click="followLink('/peoplelist')">Люди</h3>
      <h3 class="pointer" @click="followLink('/eventcalendar')">Календарь событий</h3>
      <h3 class="pointer" @click="followLink('/pointlist')">Локации</h3>
      <h3 class="pointer" @click="followLink('/joblist')">Должности</h3>
      <h3 class="pointer" @click="followLink('/eventlist')">События</h3>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  methods: {
    followLink(href) {
      this.$router.push(href);
    },
  },
};
</script>
