<template>
  <div class="noselect" style="align-self: start">
    <h2 class="center">
      <div class="pointer" @click="changeSource()" style="display: contents">
        {{ draftTitle }}
      </div>
    </h2>
    <FillPeriodWindow
      :showFlag="showFlagF"
      @close="windowClose"
      :date_begin="date_begin"
      :date_end="date_end"
      :dataSource="DBpostfix"
      :pointList="pointListVisible"
    />
    <ClearWindow
      :showFlag="showFlagC"
      @close="windowClose"
      :date_begin="date_begin"
      :date_end="date_end"
      :dataSource="DBpostfix"
      :pointList="pointListVisible"
    />
    <PublishWindow
      :showFlag="showFlagP"
      @close="windowClose"
      :date_begin="date_begin"
      :date_end="date_end"
      :dataSource="DBpostfix"
      :pointList="pointListVisible"
    />
    <div class="fc">
      <div class="heap-block">
        Смен в сутках
        <select v-model="turnsEmpty">
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </select>
      </div>
      <div class="heap-block">
        <DateSelector label="Дата нач." :val="date_begin" @change="date_begin = $event" />
      </div>
      <div class="heap-block">
        <DateSelector label="Дата кон." :val="date_end" @change="date_end = $event" />
      </div>
      <div @click.prevent="allDataRetrieve" class="high pointer">
        <button class="btn" ref="btn">Обновить</button>
      </div>
      <div @click.prevent="draftFillPeriod" class="high pointer">
        <button class="btn" ref="btn">Заполнить</button>
      </div>
      <div @click.prevent="draftClear" class="high pointer">
        <button class="btn warn" ref="btnClear">Очистить</button>
      </div>
      <div @click.prevent="draftPublish" class="high pointer">
        <button class="btn" ref="btnPublish">Опубликовать</button>
      </div>
    </div>
    <div class="w">
      <ChessTableMulti
        v-for="id in pointList"
        :key="id"
        :wParam="'wide'"
        :date_begin="date_begin"
        :date_end="date_end"
        :id_point="id"
        :updateFlag="updateFlag"
        :id="id"
        :turnsEmpty="turnsEmpty"
        :dataSource="DBpostfix"
        @pointListChange="pointListChangeLocal($event)"
      />
      <div class="b">
        <PeopleListDraft
          :filter="'work'"
          :date_begin="date_begin"
          :date_end="date_end"
          :updateData="updateData"
          :clearColorFlag="clearColorFlag"
          :dataSource="DBpostfix"
        />
      </div>
    </div>
    <div class="fr"></div>
  </div>
</template>

<style scoped>
.w {
  display: flex;
  flex-direction: row;
}
.b {
  border: solid 1px;
}
.fc {
  flex-direction: row;
  justify-content: center;
}
.fr {
  flex-direction: row;
  justify-content: right;
}
</style>

<script>
import DateSelector from "@/components/DateSelector.vue";
import ChessTableMulti from "@/components/ChessTableMulti.vue";
import PeopleListDraft from "@/components/PeopleListDraft";
import PublishWindow from "@/components/PublishWindow";
import FillPeriodWindow from "@/components/FillPeriodWindow";
import ClearWindow from "@/components/ClearWindow";
import { dateFormatJS } from "@/components-js/dateFormat";
import { datesCheck } from "@/components-js/datesCheck.js";
import { DATE_MAX_INTERVAL } from "@/config/settings";
import { lS } from "@/components-js/localStorage";
import { request } from "@/components-js/requestSrv";

const loStorage = new lS();

export default {
  components: {
    //Loader,
    ChessTableMulti,
    DateSelector,
    PeopleListDraft,
    FillPeriodWindow,
    PublishWindow,
    ClearWindow,
  },

  async created() {
    this.$root.$on("turnUpdate", this.turnChange);
  },

  beforeDestroy() {
    this.$root.$off("turnUpdate", this.turnChange);
  },

  async mounted() {
    // // get list of people
    // this.people = await request("/api/user/listall", "GET");
    // // get list of jobs
    // this.jobs = await request("/api/job/list", "GET");

    // set default values for dates and turns
    const d = new Date();
    this.date_begin = this.date_end = dateFormatJS(d);

    let tmp = loStorage.getObjectProp(this.$route.path, "date_begin");
    if (tmp) this.date_begin = tmp;

    tmp = loStorage.getObjectProp(this.$route.path, "date_end");
    if (tmp) this.date_end = tmp;

    tmp = loStorage.getObjectProp(this.$route.path, "turnsEmpty");
    if (tmp) this.turnsEmpty = tmp;

    this.datesCheckAndSet();

    this.updateData++;

    tmp = await request("/api/point/list_draft");
    this.pointList = tmp.map((item) => item.id);

    this.pointListVisible = this.pointList.slice();

    // const MutObs = new MutationObserver((changes) => console.log("MutationObserver", changes));
    // MutObs.observe(this.$refs.chesstables_all, { attributes: true,  });
  },

  data() {
    return {
      showFlagP: false,
      showFlagC: false,
      showFlagF: false,
      date_begin: null,
      date_end: null,
      updateFlag: 0,
      updateData: 0,
      turnsEmpty: 4,
      clearColorFlag: 0,
      // pointList: [1, 5, 2, 3, 4, 7, 8],
      pointList: [],
      // pointListDB: [],
      // array of non-hided points, at the begining copy of pointList
      pointListVisible: [],
      DBpostfix: "",
      draftTitleList: ["Черновик РАБОЧИЙ", "Черновик ТЕСТ"],
      draftTitle: "Черновик РАБОЧИЙ",
    };
  },

  methods: {
    changeSource() {
      if (this.DBpostfix) {
        this.DBpostfix = "";
        this.draftTitle = this.draftTitleList[0];
        this.allDataRetrieve();
      } else {
        this.DBpostfix = "_pointadmin";
        this.draftTitle = this.draftTitleList[1];
        this.allDataRetrieve();
      }
    },

    pointListChangeLocal(obj) {
      // point selected, extend pointList
      if (obj.flag && obj.id) this.pointListVisible.push(obj.id);
      if (!obj.flag && obj.id)
        this.pointListVisible = this.pointListVisible.filter((el) => el != obj.id);
    },

    datesCheckAndSet() {
      const res = datesCheck(this.date_begin, this.date_end, DATE_MAX_INTERVAL);
      this.date_begin = dateFormatJS(res.date_begin);
      this.date_end = dateFormatJS(res.date_end);
    },

    turnChange() {
      // console.log("turnChange", str);
      this.updateData++;
    },

    allDataRetrieve() {
      this.datesCheckAndSet();
      // update ChessItself
      this.updateFlag++;
      // update PeopleListmaual
      this.updateData++;
      // clear colors PeopleListDraft
      this.clearColorFlag++;
    },

    draftFillPeriod() {
      // fill period with empty turns
      // first clear period (if not empty),
      // then fill by free turns
      this.showFlagF = true;
    },

    draftClear() {
      this.showFlagC = true;
    },

    draftPublish() {
      this.showFlagP = true;
    },

    windowClose(update = false) {
      this.showFlagF = false;
      this.showFlagP = false;
      this.showFlagC = false;
      // true, if turns was deleted
      if (update) this.allDataRetrieve();
    },
  },
};
</script>
