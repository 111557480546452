<template>
  <div class="margins">
    <table>
      <tr v-if="colorsAvail">
        <td>Вариантов цвета доступно</td>
        <td>{{ colorsAvail }}</td>
      </tr>
      <tr v-else>
        <td colspan="300">Варианты цвета исчерпаны</td>
      </tr>
      <tr>
        <td colspan="300"><hr /></td>
      </tr>
      <tr v-for="el in people" :value="el.id" :key="el.id" class="nowrap">
        <td
          @click.left="markPerson(el.id)"
          @click.right.prevent="markPerson(el.id)"
          class="l"
          :style="{ backgroundColor: el.bgColor }"
        >
          <!--    :ref="`user${el.id}`" -->
          {{ el.nickname }}
        </td>
        <td>{{ el.hours }}</td>
      </tr>
    </table>
  </div>
</template>

<style scoped>
.nowrap {
  white-space: nowrap;
}
.margins {
  margin: 0.5rem;
}
.l:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>

<script>
import { colorControl } from "@/components-js/colorControl";
import { COLORS_HIGHLIGHT_USAGE } from "@/config/design";
const CC = new colorControl(COLORS_HIGHLIGHT_USAGE);

import { request } from "@/components-js/requestSrv";

export default {
  name: "PeopleListDraft",
  props: {
    label: String,
    id_peopleIn: Number,
    filter: String,
    date_begin: null,
    date_end: null,
    fontSize: null,
    updateData: null,
    clearColorFlag: null,
    dataSource: "",
  },

  data: function () {
    return { people: null, colorsAvail: CC.freeColorsCount() };
  },

  created() {
    // from Turn
    this.$root.$on("turnClick", this.markPerson);
    CC.clearAll();
    this.colorsAvail = CC.freeColorsCount();
  },

  beforeDestroy() {
    this.$root.$off("turnClick", this.markPerson);
  },

  watch: {
    updateData: function () {
      // console.log("PeopleListDraft updateData");
      this.dataRetreive();
    },
    clearColorFlag: function () {
      CC.clearAll();
      this.colorsAvail = CC.freeColorsCount();
      // for (const el of this.people) {
      //   if (this.$refs[`user${el.id}`])
      //     this.$refs[`user${el.id}`][0].style.backgroundColor = "";
      // }
      this.people.forEach((el) => (el.bgColor = null));
    },
  },

  methods: {
    setStyle(id_people) {
      const bc = CC.getUserColor(id_people);
      this.colorsAvail = CC.freeColorsCount();
      this.people.filter((el) => el.id == id_people).forEach((el) => (el.bgColor = bc));
      // this.people[i].bgColor = bc;
      // this.$refs[`user${id_people}`][0].style.backgroundColor = bc;
    },

    markPerson(id_people) {
      // console.log("CHU", CC.CHU);
      const color = CC.getUserColor(id_people);
      // console.log("getUserColor", color);
      if (color) {
        CC.clearColor(color);
        this.$root.$emit("markPerson", id_people, null);
        this.setStyle(id_people);
        // this.TURNS.highlightGroupByUser(id_people, null);
      } else {
        const color = CC.getFreeColor();
        // console.log("getFreeColor", color);
        if (color) {
          CC.setUserColor(id_people, color);
          this.$root.$emit("markPerson", id_people, color);
          this.setStyle(id_people);
          // this.TURNS.highlightGroupByUser(id_people, color);
        } else return;
      }
    },

    async dataRetreive() {
      if (!this.filter) {
        this.people = await request("/api/user/listall");
        return;
      }
      if (this.filter == "work")
        this.people = await request("/api/turndraft/hoursbypeople", "POST", {
          status: this.filter,
          date_begin: this.date_begin,
          date_end: this.date_end,
          dataSource: this.dataSource,
        });
      this.people.sort((a, b) => {
        if (a.id == 0) return -1;
        if (!a.nickname) a.nickname = a.surname + " " + a.name;
        if (!b.nickname) b.nickname = b.surname + " " + b.name;
        if (a.nickname > b.nickname) return 1;
        if (a.nickname < b.nickname) return -1;
        return 0;
      });
    },
  },
  // updated() {
  //   // when element fully loaded from server
  //   this.$refs.people.value = this.id_peopleIn;
  // },
};
</script>
