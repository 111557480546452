<template>
  <div class="loader">
    <div class="blob-2"></div>
    <!-- <div>
      <span>Загрузка{{ cnt }}</span>
    </div> -->
  </div>
</template>

<style scoped>
.loader {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: center;
}

.blob-2 {
  width: 70px;
  height: 70px;
  padding: 10px;
  background: #fff;
  mix-blend-mode: darken;
  display: grid;
  filter: blur(4px) contrast(10) hue-rotate(270deg);
}
.blob-2:before,
.blob-2:after {
  content: "";
  grid-area: 1/1;
  animation: bo2 3s infinite linear;
  background: #ff00ff;
  border-radius: 50%;
}
.blob-2:after {
  animation-delay: -0.8s;
}
@keyframes bo2 {
  12.5% {
    border-radius: 37% 63% 70% 30% / 30% 62% 38% 70%;
  }
  25% {
    border-radius: 84% 16% 15% 85% / 55% 79% 21% 45%;
  }
  37.5% {
    border-radius: 73% 27% 74% 26% / 64% 32% 68% 36%;
  }
  50% {
    border-radius: 73% 27% 18% 82% / 52% 32% 68% 48%;
  }
  62.5% {
    border-radius: 33% 67% 18% 82% / 52% 75% 25% 48%;
  }
  75% {
    border-radius: 12% 88% 69% 31% / 10% 66% 34% 90%;
  }
  87.5% {
    border-radius: 50% 50% 70% 30% / 52% 62% 38% 48%;
  }
}
</style>

<script>
export default {
  name: "Loader",
  data: function () {
    return {
      // cnt: "...",
      // interval: null,
    };
  },

  // mounted: function () {
  //   // console.log("setInterval");
  //   this.interval = setInterval(() => (this.cnt += "."), 500);
  // },

  // beforeDestroy: function () {
  //   // console.log("clearInterval");
  //   clearInterval(this.interval);
  // },
};
</script>
