import Vue from "vue";
import App from "@/App.vue";
import router from "@/router-vue/index.js";

Vue.config.productionTip = true;
Vue.config.devtools = true;

new Vue({
  router,
  data: { user: "defined in main.js" },
  render: (h) => h(App),
}).$mount("#app");
