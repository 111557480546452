<template>
  <div v-if="flag" class="margins2">
    <br v-if="labelPosition != 'left'" />
    <template v-for="(el, i) in peopleBirthList">
      {{ i > 0 ? "&nbsp;" : "" }} {{ "\u{1F382}" }} {{ el.nickname }}
    </template>
    {{ label }}
  </div>
</template>

<script>
import { request } from "@/components-js/requestSrv";

export default {
  name: "BirthDay",
  props: {
    date: Date,
    label: String,
    labelPosition: String,
  },

  data: function () {
    return { peopleBirthList: [], flag: Number };
  },

  async created() {
    this.flag = 0;
    this.peopleBirthList = await request("/api/user/listbirth");
    if (this.peopleBirthList) {
      this.peopleBirthList = this.peopleBirthList.filter(
        (i) =>
          parseInt(i.month) == new Date(this.date).getMonth() + 1 &&
          parseInt(i.day) == new Date(this.date).getDate()
      );
      this.flag = this.peopleBirthList.length;
    }
  },
};
</script>
