<template>
  <div v-show="showFlag">
    <section class="modalTurn">
      <div class="modalTurn-content">
        {{ type }} {{ dataSource }}
        <div class="modalWin">
          <h3 v-if="turn.id_turn">Редактировать смену</h3>
          <h3 v-else>Добавить смену</h3>
          <div class="flexR center">
            {{ dateFormatHuman(turn.turn_date, "w") }}
          </div>
          <br />
          <div class="flexR center">
            <PointSelector
              label=""
              :id_pointIn="turn.id_point"
              @change="turn.id_point = parseInt($event)"
            />
          </div>
          <br />
          <div class="flexR center">
            <button class="btn" @click="turnStatusRotate">
              {{ turn.status == TURN_CLOSE_VAL ? "ЗАКРЕП" : "СВОБОДНАЯ СМЕНА" }}
            </button>
          </div>
          <br />
          <div v-show="turn.status == TURN_CLOSE_VAL">
            <div ref="human" class="flexR center">
              <PeopleSelector
                label=""
                filter="work"
                :id_peopleIn="turn.id_people"
                @change="peopleSelectorHandler(parseInt($event))"
              />
            </div>
            <br />
            <div class="flexR center">
              <select disabled v-model="turn.id_job">
                <option v-for="el in jobs" :value="el.id" :key="el.id">
                  {{ el.job_name }}
                </option>
              </select>
            </div>
            <!-- &nbsp;&nbsp;&nbsp;
            <div class="flexR center">
              <input disabled :value="turn.turn_rate" style="width: 32px; text-align: center" />
            </div> -->
          </div>
          <br />
          <div class="err">{{ errMessage }}&nbsp;</div>
          <br />
          <div ref="hours" class="flexR center" style="justify-content: space-evenly">
            <HourSelector
              label="Час нач."
              :offset="0"
              :value="turn.hour_begin"
              @change="turn.hour_begin = parseInt($event)"
            />
            <MinuteSelector
              label=""
              :offset="0"
              :value="turn.minute_begin"
              @change="turn.minute_begin = parseInt($event)"
            />
            <HourSelector
              label="Час кон."
              :offset="0"
              :value="turn.hour_end"
              @change="turn.hour_end = parseInt($event)"
            />
            <MinuteSelector
              label=""
              :offset="0"
              :value="turn.minute_end"
              @change="turn.minute_end = parseInt($event)"
            />
          </div>
          <br />
          <br />
          <div class="flexR center">
            Доп.часы&nbsp;
            <input
              type="checkbox"
              v-model="timeAddFlag"
              :checked="timeAddFlag"
              style="vertical-align: middle"
            />
          </div>
          <br />
          <div v-show="timeAddFlag" class="flexR center">
            <HourSelectorAdd
              :value="turn.time_add"
              @change="turn.time_add = parseInt($event)"
            />
            &nbsp;
            <textarea v-model="turn.time_add_note" />
            <br />
            <br />
          </div>

          <br />
          <button v-if="turn.id_turn" v-on:click="turnDelete()" class="btn warn">
            Удалить
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button v-on:click="windowClose(0, null)" class="btn cancel">Отмена</button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button ref="applyButton" v-on:click="Apply()" class="btn">
            {{ turn.id_turn ? "Применить" : "Добавить" }}
          </button>
          <br />
          <a v-if="turn.id_turn" href="" class="grey normal" @click.prevent="turnHistoryGet()">
            история
          </a>
          <div v-if="turnHistory.length">
            <div v-for="(el, i) in turnHistory" :key="i">
              {{
                el.nick +
                ", " +
                el.date +
                ", " +
                offsetApply(el.hour_begin) +
                " - " +
                offsetApply(el.hour_end)
              }}
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<style scoped>
.grey {
  color: grey;
  text-decoration: underline;
}
textarea {
  display: block;
  height: 100%;
}
</style>

<script>
import PointSelector from "@/components/PointSelector";
import PeopleSelector from "@/components/PeopleSelector.vue";
import HourSelector from "@/components/HourSelector";
import HourSelectorAdd from "@/components/HourSelectorAdd";
import MinuteSelector from "@/components/MinuteSelector";
import { dateFormatHuman } from "@/components-js/dateFormat";
import { request } from "@/components-js/requestSrv";
import { TURN_CLOSE_VAL, TURN_FREE_VAL } from "@/components-js/turns";
import { timeFormat, offsetApply } from "@/components-js/timeOffset";

export default {
  name: "TurnWindow",
  components: { PointSelector, PeopleSelector, HourSelector, HourSelectorAdd, MinuteSelector },
  props: {
    showFlag: Boolean,
    turn: Object,
    people: Array,
    jobs: Array,
    type: null,
    dataSource: "",
  },

  data() {
    return {
      errMessage: null,
      TURN_CLOSE_VAL: TURN_CLOSE_VAL,
      TURN_FREE_VAL: TURN_FREE_VAL,
      timeAddFlag: false,
      turnHistory: [],
      route: null,
      id_peoplePrev: null,
    };
  },

  mounted() {
    if (this.type == "real") this.route = "turn";
    if (this.type == "draft") this.route = "turndraft";
  },

  watch: {
    showFlag: function (newVal) {
      // clear turn history
      this.turnHistory = [];

      // window appeared, set default values
      if (newVal) {
        document.addEventListener("keyup", this.keyPressHandler);
        // time_add controls
        this.turn.time_add ? (this.timeAddFlag = true) : (this.timeAddFlag = false);

        // if (this.turn.status == TURN_FREE_VAL) {
        //   this.setFreeTurn();
        // }

        // window first open
        if (this.turn.status == null) {
          if (this.turn.id_people) {
            this.turn.status = TURN_CLOSE_VAL;
          } else {
            this.setFreeTurn();
          }
        }

        // existing turn opened
        if (this.turn.status == "closed") {
          if (this.turn.id_people) this.id_peoplePrev = this.turn.id_people;
          if (!this.turn.id_people && this.id_peoplePrev)
            this.peopleSelectorHandler(this.id_peoplePrev);
        }
      } else document.removeEventListener("keyup", this.keyPressHandler);
    },

    timeAddFlag: function (newVal) {
      if (!newVal) {
        this.turn.time_add = 0;
      }
    },

    // "turn.id_people": function (newVal) {
    //   console.log("turn.id_people", newVal);
    //   console.log("this.showFlag", this.showFlag);
    //   if (this.turn.status == TURN_FREE_VAL) return;
    //   // console.log("oldVal, newVal", oldVal, newVal);
    //   this.turn.id_job = this.people.find((el) => el.id == newVal).id_job;
    // },

    "turn.id_job": function (newVal) {
      if (this.turn.status == TURN_FREE_VAL || !newVal) return;
      this.turn.turn_rate = this.jobs.find((el) => el.id == newVal).job_rate;
    },
  },

  methods: {
    offsetApply(h /*, l*/) {
      return offsetApply(h /*, l*/);
    },

    timeFormat(time, flag) {
      return timeFormat(time, flag);
    },

    async turnHistoryGet() {
      if (this.turnHistory.length) {
        this.turnHistory = [];
        return;
      }
      this.turnHistory = await request(`/api/${this.route}/history`, "POST", {
        id_turn: this.turn.id_turn,
      });
    },

    keyPressHandler(evt) {
      if (evt.code == "Escape") this.windowClose(0, null);
      if (evt.code == "Enter") this.Apply();
    },

    dateFormatHuman(d, w) {
      return dateFormatHuman(d, w);
    },

    peopleSelectorHandler(id_people) {
      if (id_people == null) return;
      this.turn.id_people = id_people;
      this.id_peoplePrev = id_people;
      if (this.people.length)
        this.turn.id_job = this.people.find((el) => el.id == id_people).id_job;
    },

    setFreeTurn() {
      this.turn.status = TURN_FREE_VAL;
      this.turn.id_people = null;
      this.turn.id_job = null;
      this.turn.turn_rate = null;
    },

    setCloseTurn() {
      this.turn.status = TURN_CLOSE_VAL;
      this.peopleSelectorHandler(this.id_peoplePrev);
    },

    async Apply() {
      // check, that human is selected
      if (this.turn.status != TURN_FREE_VAL && !this.turn.id_people) {
        this.$refs.human.classList.add("warn-border");
        setTimeout(() => this.$refs.human.classList.remove("warn-border"), 300);
        return;
      }

      // check for correct time interval include minutes
      if (
        parseInt(this.turn.hour_begin) * 60 + parseInt(this.turn.minute_begin) >=
        parseInt(this.turn.hour_end) * 60 + parseInt(this.turn.minute_end)
      ) {
        this.$refs.hours.classList.add("warn-border");
        setTimeout(() => {
          this.$refs.hours.classList.remove("warn-border");
        }, 500);
        return;
      }

      // disable button for prevent turn dupliction
      this.$refs.applyButton.disabled = true;

      // check for intersection with other turns
      if ((await this.turnMatches()) > 0) {
        this.$refs.applyButton.classList.add("warn");
        setTimeout(() => this.$refs.applyButton.classList.remove("warn"), 300);
        this.errMessage = "пересечение с другой сменой";
        setTimeout(() => (this.errMessage = null), 1000);
        this.$refs.applyButton.disabled = false;
        return;
      }

      if (this.turn.id_turn) {
        //update record
        this.turn.dataSource = this.dataSource; // add postfix for pointadmin draft
        const res = await request(`/api/${this.route}/update`, "POST", this.turn);
        delete this.turn.dataSource; // and remove it
        // multuturn for
        this.$root.$emit("turnUpdate", "update");
        this.$refs.applyButton.disabled = false;
        if (res.affectedRows) this.windowClose(this.turn, "upd");
      } else {
        // insert record
        this.turn.dataSource = this.dataSource; // add postfix for pointadmin draft
        const res = await request(`/api/${this.route}/insert`, "POST", this.turn);
        delete this.turn.dataSource; // and remove it
        this.$refs.applyButton.disabled = false;
        if (res.affectedRows == 1 && res.insertId) {
          // multuturn for
          this.$root.$emit("turnUpdate", "insert");
          this.turn.id_turn = res.insertId;
          this.windowClose(this.turn, "add");
        }
      }
    },

    windowClose(turnId, action) {
      this.$emit("hide", turnId, action);
    },

    async turnMatches() {
      this.turn.dataSource = this.dataSource; // add postfix for pointadmin draft
      const data = await request(`/api/${this.route}/validation`, "POST", this.turn);
      delete this.turn.dataSource; // and remove it
      return parseInt(data[0].cnt);
    },

    turnStatusRotate() {
      this.turn.status = this.turn.status == TURN_CLOSE_VAL ? TURN_FREE_VAL : TURN_CLOSE_VAL;
      if (this.turn.status == TURN_FREE_VAL) {
        this.setFreeTurn();
      } else {
        this.setCloseTurn();
      }
    },

    async turnDelete() {
      // DB request
      if (!confirm("Удалить смену?")) return;
      const res = await request(`/api/${this.route}/delete`, "DELETE", {
        id_turn: this.turn.id_turn,
        dataSource: this.dataSource,
      });
      // delete fault
      if (res.affectedRows === 0) {
        // add ERROR effect !!!
        return;
      }
      // multuturn for
      this.$root.$emit("turnUpdate", "delete");
      this.windowClose(this.turn, "del");
    },
  },
};
</script>
