<template>
  <div v-if="showFlag">
    <section class="modalTurn">
      <div class="modalTurn-content">
        <div class="modalWin">
          <h3 v-if="point.id">Карточка локации</h3>
          <h3 v-else>Добавить локацию</h3>

          <table style="width: 90%">
            <tr>
              <td class="R">Название</td>
              <td class="L">
                <input type="text" v-model.trim="point.nickname" ref="nickname" />
              </td>
            </tr>

            <tr>
              <td class="R">Адрес</td>
              <td class="L"><input type="text" v-model.trim="point.address" /></td>
            </tr>

            <tr>
              <td class="R">Тип точки</td>
              <td class="L">
                <select v-model="point.type">
                  <option value="кофейня">Кофейня</option>
                  <option value="тренинг">Тренинг</option>
                  <option value="цех">Цех</option>
                </select>
              </td>
            </tr>

            <tr>
              <td class="R">Время работы</td>
              <td class="L"><input type="text" v-model.trim="point.work_time" /></td>
            </tr>

            <tr>
              <td class="R">Статус</td>
              <td class="L">
                <select v-model="point.status">
                  <option value="open">Открыта</option>
                  <option value="closed">Закрыта</option>
                </select>
              </td>
            </tr>

            <tr>
              <td class="R">Показать на 'Сегодня'</td>
              <td class="L"><input type="checkbox" v-model="point.show_on_today" /></td>
            </tr>

            <tr>
              <td class="R">Связь с ikko</td>
              <td class="L"><textarea disabled v-model.trim="point.ikko_link" /></td>
            </tr>

            <tr>
              <td class="R">Продажи (чеки)</td>
              <td class="L"><input type="number" v-model.trim="point.sales" /></td>
            </tr>

            <tr>
              <td class="R">Продажи (к-т)</td>
              <td class="L"><input type="text" v-model.trim="point.salesk" ref="salesk" /></td>
            </tr>

            <tr>
              <td class="R">Дата открытия</td>
              <td class="L">
                <DateSelector
                  label=""
                  labelPosition="left"
                  :val="point.date_open"
                  @change="point.date_open = $event"
                />
              </td>
            </tr>

            <tr>
              <td class="R">Часовой пояс (от Мск)</td>
              <td class="L"><input type="number" v-model.trim="point.timezone_hour" /></td>
            </tr>

            <tr>
              <td class="R">Показать в 'Черновике'</td>
              <td class="L"><input type="checkbox" v-model="point.show_on_draft" /></td>
            </tr>

            <tr>
              <td class="R">Час открытия {{ point.hour_open }}</td>
              <td class="L">
                <HourSelector
                  label=""
                  :offset="0"
                  :value="point.hour_open"
                  @change="point.hour_open = parseInt($event)"
                />
              </td>
            </tr>
            <tr>
              <td class="R">Час закрытия {{ point.hour_close }}</td>
              <td class="L">
                <HourSelector
                  label=""
                  :offset="0"
                  :value="point.hour_close"
                  @change="point.hour_close = parseInt($event)"
                />
              </td>
            </tr>
          </table>

          <div class="err">{{ errMessage }}&nbsp;</div>
          <br />
          <div class="col-revers">
            <button ref="applyButton" v-on:click.prevent="Apply()" class="btn">
              {{ point.id ? "Применить" : "Добавить" }}
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button v-on:click.prevent="Cancel()" class="btn cancel">Отмена</button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button v-if="point.id" v-on:click.prevent="Delete()" class="btn warn">
              Удалить
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<style scoped>
.col-revers {
  display: flex;
  flex-direction: row-reverse;
}
div {
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: middle;
}
.col-revers {
  display: flex;
  flex-direction: row-reverse;
}
td.R {
  text-align: right;
}
td.L {
  text-align: left;
}
table {
  border-spacing: 0.4rem;
}
.width70 {
  width: 70%;
}
</style>
<script>
import DateSelector from "@/components/DateSelector.vue";
import HourSelector from "@/components/HourSelector";
import { dateFormatJS } from "@/components-js/dateFormat";
import { request } from "@/components-js/requestSrv";
import { POINT_SALES, POINT_SALES_K, POINT_TIMEZONE } from "../config/settings";

export default {
  name: "PointWindow",
  components: {
    DateSelector,
    HourSelector,
  },
  props: {
    showFlag: Boolean,
    pointIn: {},
  },

  data() {
    return {
      errMessage: null,
      point: {},
      dateFormatJS,
    };
  },

  watch: {
    showFlag: function (newValue) {
      // window appeared
      if (newValue) {
        document.addEventListener("keyup", this.keyPressHandler);

        //if (!this.pointIn) this.point = new Object();

        if (this.pointIn) {
          // create copy
          for (let i in this.pointIn) {
            this.point[i] = this.pointIn[i];
          }
        } else {
          this.point = new Object();
          this.initDefault();
        }
      } else document.removeEventListener("keyup", this.keyPressHandler);
    },
  },

  methods: {
    initDefault() {
      this.point.address = "Адрес";
      this.point.nickname = "Новая точка";
      this.point.type = "кофейня";
      this.point.work_time = "днём";
      this.point.status = "open";
      this.point.show_on_today = true;
      this.point.show_on_draft = true;
      this.point.ikko_link = "связь с айко";
      this.point.sales = POINT_SALES;
      this.point.salesk = POINT_SALES_K;
      this.point.date_open = dateFormatJS(new Date());
      this.point.timezone_hour = parseInt(POINT_TIMEZONE);
      this.point.hour_open = 7;
      this.point.hour_close = 23;
    },

    setType(ref, type) {
      this.$refs[ref].setAttribute("type", type);
    },

    keyPressHandler(evt) {
      if (evt.code == "Escape") this.Cancel();
    },

    async Apply() {
      if (!this.point.nickname.length) {
        this.$refs.nickname.classList.add("warn-border");
        setTimeout(() => this.$refs.nickname.classList.remove("warn-border"), 300);
        return;
      }

      // JSON format
      // if (this.point.work_time) this.point.work_time = JSON.stringify(this.point.work_time);

      // check salesk for float
      this.point.salesk = String(this.point.salesk).replace(",", ".");
      if (this.point.salesk != parseFloat(this.point.salesk)) {
        this.$refs.salesk.classList.add("warn-border");
        setTimeout(() => this.$refs.salesk.classList.remove("warn-border"), 300);
        return;
      }

      // check unique first
      const check = await request("/api/point/checkunique", "POST", {
        nickname: this.point.nickname,
        id: this.point.id,
      });
      if (check[0].cnt) {
        this.errMessage = "Название уже используется";
        setTimeout(() => (this.errMessage = null), 1500);
        return;
      }

      if (this.point.id) {
        const res = await request("/api/point/update", "POST", this.point);
        if (res.affectedRows) this.windowClose();
        else {
          this.$refs.applyButton.classList.add("warn");
          setTimeout(() => this.$refs.applyButton.classList.remove("warn"), 300);
          return;
        }
      } else {
        // const check = await request("/api/point/checkunique", "POST", {
        //   nickname: this.point.nickname,
        // });
        // if (check[0].cnt) {
        //   this.errMessage = "Название уже используется";
        //   setTimeout(() => (this.errMessage = null), 1500);
        //   return;
        // }
        const res = await request("/api/point/insert", "POST", this.point);
        if (res.affectedRows == 1 && res.insertId) {
          this.point.id = res.insertId;
          this.windowClose();
        }
      }
    },

    async Delete() {
      if (!confirm("Удалить локацию?")) return;
      let res = await request("/api/point/deletecheck", "POST", { id: this.point.id });
      if (res[0].cnt) {
        this.errMessage = "Смен с участием локации: " + res[0].cnt;
        setTimeout(() => (this.errMessage = null), 1500);
        return;
      }
      res = await request("/api/point/delete", "DELETE", {
        id: this.point.id,
      });
      if (res.affectedRows) {
        this.point.id *= -1;
        this.windowClose();
      } else {
        this.errMessage = "ошибка удаления";
        setTimeout(() => (this.errMessage = null), 1500);
      }
    },

    Cancel() {
      this.point.id = 0;
      this.windowClose();
    },

    windowClose() {
      this.$emit("hide", this.point);
    },
  },
};
</script>
