<template>
  <div :class="{ heap: labelPosition == 'left' }" @click="$refs.people.focus()">
    {{ label }}
    <select @change="$emit('change', $event.target.value)" ref="people">
      <option v-for="el in people" :value="el.id" :key="el.id">
        {{ el.nickname }}
      </option>
    </select>
  </div>
</template>

<script>
import { request } from "@/components-js/requestSrv";

export default {
  name: "PeopleSelector",
  props: {
    label: String,
    labelPosition: String,
    id_peopleIn: null,
    filter: String,
  },

  data: function () {
    return { people: null };
  },

  watch: {
    id_peopleIn: function (newVal) {
      this.$refs.people.value = newVal;
    },
  },

  async beforeMount() {
    if (!this.filter) {
      this.people = await request("/api/user/listall");
      return;
    }
    if (this.filter == "work") this.people = await request("/api/user/listwork");
    this.people.sort((a, b) => {
      if (!a.nickname) a.nickname = a.surname + " " + a.name;
      if (!b.nickname) b.nickname = b.surname + " " + b.name;
      if (a.nickname > b.nickname) return 1;
      if (a.nickname < b.nickname) return -1;
      return 0;
    });
  },

  updated() {
    // when element fully loaded from server
    this.$refs.people.value = this.id_peopleIn;
  },
};
</script>
