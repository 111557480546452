import { COLORS_HIGHLIGHT, TURN_OWN_COLOR } from "@/config/design";
import { request } from "@/components-js/requestSrv";

const TURN_CLOSE_VAL = "closed";
const TURN_FREE_VAL = "free";
const TURN_REQUEST_VAL = "request";

class TURNS_ {
  turns = [];

  // people array
  people = new Array();

  // highLight array
  colorsUsed = new Array();

  constructor(id) {
    this.UID = id;
  }

  async fill(list) {
    this.clear();
    Object.assign(this.turns, list);
    this.people = await request("/api/user/listall", "GET");
    for (let i in this.turns) this.setCaption(this.turns[i].id_turn);
    this.highLightOwnTurns();
    this.highLightFromSessionStorage();
    // show colors and captions
    this.turns.splice(0, 0);
  }

  clear() {
    this.turns.length = 0;
  }

  // add new turn
  add(turn) {
    this.turns.push({ Object });
    //for (let x in turn) this.turns[this.turns.length - 1][x] = turn[x];
    Object.assign(this.turns[this.turns.length - 1], turn);
    this.setCaption(turn.id_turn);
  }

  // update existing turn
  update(turn) {
    const i = this.getIndex(turn.id_turn);
    Object.assign(this.turns[i], turn);
    this.clearHighlight(turn.id_turn);
    this.highLightFromSessionStorage();
    this.setCaption(turn.id_turn);
  }

  // delete existing turn
  delete(id) {
    const i = this.getIndex(id);
    this.turns.splice(i, 1);
  }

  // turns array for output
  getList() {
    return this.turns;
  }

  getPoint(id) {
    return this.turns[this.getIndex(id)].id_point;
  }

  getPeople(id_turn) {
    return this.turns[this.getIndex(id_turn)].id_people;
  }

  getJob(id) {
    return this.turns[this.getIndex(id)].id_job;
  }

  getDate(id) {
    return this.turns[this.getIndex(id)].turn_date;
  }

  getHourBegin(id) {
    return this.turns[this.getIndex(id)].hour_begin;
  }

  getHourEnd(id) {
    return this.turns[this.getIndex(id)].hour_end;
  }

  getMinuteBegin(id) {
    return this.turns[this.getIndex(id)].minute_begin;
  }

  getMinuteEnd(id) {
    return this.turns[this.getIndex(id)].minute_end;
  }

  getTimeAdd(id) {
    return this.turns[this.getIndex(id)].time_add;
  }

  getTimeAddNote(id) {
    return this.turns[this.getIndex(id)].time_add_note;
  }

  getOrder(id) {
    return this.turns[this.getIndex(id)].turn_order;
  }

  getRate(id) {
    return this.turns[this.getIndex(id)].turn_rate;
  }

  getOrderAbs(id) {
    return this.turns[this.getIndex(id)].orderAbs;
  }

  getStatus(id) {
    return this.turns[this.getIndex(id)].status;
  }

  getCaption(id_turn) {
    return this.turns[this.getIndex(id_turn)].caption;
  }

  getName(id_turn) {
    const id_people = this.getPeople(id_turn);
    const i = this.people.findIndex((el) => el.id == id_people);
    return this.people[i].name;
  }

  getSurname(id_turn) {
    const id_people = this.getPeople(id_turn);
    const i = this.people.findIndex((el) => el.id == id_people);
    return this.people[i].surname;
  }

  getNickname(id_turn) {
    const id_people = this.getPeople(id_turn);
    const i = this.people.findIndex((el) => el.id == id_people);
    return this.people[i].nickname;
  }

  setCaption(id_turn) {
    switch (this.getStatus(id_turn)) {
      case TURN_FREE_VAL:
        this.turns[this.getIndex(id_turn)].caption = "свободная смена";
        break;
      default:
        for (let p in this.people) {
          this.getPeople(id_turn) == this.people[p].id
            ? this.people[p].nickname
              ? (this.turns[this.getIndex(id_turn)].caption = this.people[p].nickname)
              : (this.turns[this.getIndex(id_turn)].caption =
                  this.people[p].surname + " " + this.people[p].name)
            : null;
        }
    }
    // if (this.getTimeAdd(id_turn))
    //   this.turns[this.getIndex(id_turn)].caption += " +" + this.getTimeAdd(id_turn);
  }

  rotateCaption(id_turn) {
    // свободная смена
    if (this.getStatus(id_turn) == TURN_FREE_VAL) return;
    if (!this.getNickname(id_turn)) return;
    let newCaption = null;
    if (this.getCaption(id_turn) == this.getNickname(id_turn))
      newCaption = this.getSurname(id_turn) + " " + this.getName(id_turn);
    else newCaption = this.getNickname(id_turn);

    const id_people = this.getPeople(id_turn);
    this.turns.forEach((el) => {
      if (el.id_people == id_people) el.caption = newCaption;
    });
    this.turns.splice(0, 0);
  }

  // when new empty row added
  changeOrderAbs(date, step) {
    // for (let X of this.TURNS.getList) {
    //   if (X && new Date(X.turn_date) > new Date(date)) {
    //     X.orderAbs += 1;
    //   }
    // }
    this.turns.forEach((el) =>
      new Date(el.turn_date) > new Date(date) ? (el.orderAbs += step) : null
    );
  }

  ///////////////////
  // highlite engine
  ///////////////////
  colorGet() {
    // if all colors used, free random one
    if (this.colorsUsed.length == COLORS_HIGHLIGHT.length) {
      const i = parseInt(Math.random() * (this.colorsUsed.length - 1));
      this.colorFree(this.colorsUsed[i]);
    }
    // find and return free color
    for (let color of COLORS_HIGHLIGHT) {
      if (this.colorsUsed.includes(color)) continue;
      this.colorsUsed.push(color);
      return color;
    }
  }

  colorFree(color) {
    this.colorsUsed.splice(this.colorsUsed.indexOf(color), 1);
    sessionStorage.removeItem(color);
    // clear turns and sessionStorage
    this.turns.forEach((el) => {
      el.highLight == color && (el.highLight = null);
    });
  }

  clearHighlight(id) {
    this.turns[this.getIndex(id)].highLight = null;
  }

  isHighlighted(id) {
    return this.turns[this.getIndex(id)].highLight;
  }

  getHighlight(id) {
    return this.turns[this.getIndex(id)].highLight;
  }

  highlightGroupByUser(id_user, color) {
    const el = this.turns.find((el) => el.id_people == id_user);
    if (!el) return;
    this.highlightGroup(el.id_turn, color);
  }

  highlightGroup(id, color) {
    if (this.getStatus(id) == TURN_FREE_VAL) return; // can't color free turn
    if (this.getPeople(id) == this.UID) return; // can't color own turn
    // if alredy highlighted, then just clear by color
    if (!color) {
      this.colorFree(this.getHighlight(id));
    } else {
      // highlight by id_people
      const id_people = this.turns[this.getIndex(id)].id_people;
      if (!id_people) {
        this.log(id);
        return;
      }

      // find all turns with this id_people and highlight
      this.turns.forEach((el) => el.id_people == id_people && (el.highLight = color));
      // save to session storage
      sessionStorage.setItem(color, id_people);
    }
    this.turns.splice(0, 0);
  }

  // highlight from localstorage
  // localstorage may store colors, that not used in curent turns, so,
  // need to understandm, which ones non used in loaded set of turns
  highLightFromSessionStorage() {
    // this.colorsUsed = [];
    // for (let color of COLORS_HIGHLIGHT) {
    //   let id_people = sessionStorage.getItem(color);
    //   if (id_people == this.UID) continue; // can't color own turns
    //   if (id_people) {
    //     let foundFlag = 0;
    //     this.turns.forEach((el) => {
    //       if (el.id_people == id_people) {
    //         el.highLight = color;
    //         foundFlag = 1;
    //       }
    //     });
    //     if (foundFlag) this.colorsUsed.push(color);
    //   }
    // }
  }

  // own turn always the same color
  highLightOwnTurns() {
    this.turns.forEach((el) => {
      if (el.id_people == this.UID) el.highLight = TURN_OWN_COLOR;
    });
  }

  // internal function
  getIndex(id) {
    const res = this.turns.findIndex((el) => el.id_turn == id);
    if (res != -1) return res;
    this.log(id);
    return null;
  }

  // log function
  log(id) {
    console.log("turn not found, id=" + id);
  }
}

export { TURNS_, TURN_CLOSE_VAL, TURN_FREE_VAL, TURN_REQUEST_VAL };
