<template>
  <div class="border-round" v-if="flag">
    <div class="flex">
      <div>
        <h4 class="margins1">Люди на распределение:</h4>
        <div class="margins2">
          <template v-for="(b, i) in dataPeople">
            <div :key="b.id">{{ `${i + 1}. ` }}{{ b.nickname }}</div>
          </template>
        </div>
      </div>
      <div>
        <h4 class="margins1">Люди вне "автомата":</h4>
        <div class="margins2">
          <template v-for="(b, i) in dataPeopleManually">
            <div :key="b.id">{{ `${i + 1}. ` }}{{ b.nickname }}</div>
          </template>
        </div>
      </div>
    </div>
    <h4 class="margins1">Общие правила</h4>
    <div class="margins2">
      <template v-for="(p, i) of dataPropsCommon">
        <div :key="i">{{ propsCommonExplain(i) }} {{ p }}</div>
      </template>
    </div>

    <h4 class="margins1">Индивидуальные правила</h4>
    <template v-for="(p, i) in dataPeople">
      <template v-if="dataPropsPersonalDailyFilter(p.id).length">
        <br :key="i" />
        <div class="margins1" :key="i">{{ p.nickname }}</div>
        <template v-for="(ex, j) in dataPropsPersonalDailyFilter(p.id)">
          <div :key="j">
            <div class="margins2">
              {{ dateFormatHuman(ex.props.date, "w") }}: {{ propsPersonalExplain(ex.props) }}
            </div>
          </div>
        </template>
      </template>
    </template>

    <h4 class="margins1">Недельное расписание</h4>
    <div class="margins2">
      <template v-for="(p, i) of weekRepresent">
        <div v-if="p" :key="i">
          <b>{{ nickGet(i) }}</b>
          <template v-for="(cp, j) of dataPropsCommonFilter(i)">
            <div :key="j">{{ propsCommonExplain(j) }} {{ cp }}</div>
          </template>
          <table class="center">
            <tr>
              <template v-for="w of WEEKDAYS">
                <td :key="w" class="border-round td center">
                  {{ WEEKDAYS_SHORTLY[w] }}
                </td>
              </template>
            </tr>
            <tr>
              <template v-for="(x, j) of weekRepresent[i]">
                <td :key="j" class="border-box" :class="{ rest: cell(x, 'rest') }">
                  <div v-if="cell(x, 'rest')">выходной</div>
                  <div v-else>
                    <div>{{ cell(x, "p_daytime") }}</div>
                    <div>{{ cell(x, "p_point") }}</div>
                  </div>
                </td>
              </template>
            </tr>
          </table>
          <!-- <div>
            Инди
            <div>
              {{ dataPropsPersonalDailyFilter(i) }}
            </div>
          </div> -->
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped>
td {
  width: 4rem;
}
</style>

<script>
import { request } from "@/components-js/requestSrv";
import { dateFormatHuman } from "@/components-js/dateFormat";
import { POINTS_SHORTLY, WEEKDAYS, WEEKDAYS_SHORTLY, DAYTIME } from "@/config/settings";

export default {
  name: "AutoPeopleList",
  props: {
    date_begin: String,
    date_end: String,
  },

  data: function () {
    return {
      flag: 0,
      // list of people to spray
      dataPeople: [],
      // list of people already sprayed
      dataPeopleManually: [],
      // DEFAULT props (100, 4/2, 1)
      dataPropsCommon: {},
      // all props in a heap
      dataPropsCommonAll: [],
      // daily exceptions
      dataPropsPersonalDaily: [],
      // daily exceptions
      dataPropsPersonalWeekly: [],
      // representation of week props for show
      weekRepresent: [],
      // for template usage
      WEEKDAYS: WEEKDAYS,
      WEEKDAYS_SHORTLY: WEEKDAYS_SHORTLY,
    };
  },

  async mounted() {
    // common props MIXED (default and personal values)
    this.dataPropsCommonAll = await request("/api/auto/getpropscommon", "POST");
    // common properties DEFAULT ONLY

    //const dataPropsCommon = {};
    for (const P of this.dataPropsCommonAll) {
      if (P.id_user == 0) {
        for (const j in P.props) this.dataPropsCommon[j] = parseInt(P.props[j]);
      }
    }

    // people, that set manually by admin
    this.dataPeopleManually = await request("/api/auto/getpeoplesetmanual", "POST", {
      status: "work",
    });

    // exclude some people (SHOULD CREATE SEPARATE TABLE FOR THEM)
    // this.dataPeople = await request("/api/auto/getpeopleforturns", "POST", {
    //   status: "work",
    //   exclude_people: "3,2,5,8,17,23",
    //   //include_people: "22,17,20,10,32,9,29,12,35",
    //   include_people: null,
    //   include_job: "1,2,3,4",
    // });
    this.dataPeople = await request("/api/auto/getpeopleforturns", "POST");

    // fill people list with props and reset counters
    for (const p of this.dataPeople) {
      // set skill depends on id_job
      p.id_job == 1 ? (p.p_skill = "low") : (p.p_skill = "middle");
      // set counters to zero
      p.turns_in_a_day = 0;
      // p.turns_in_row = 0;
      p.turns_in_row = 0;
      p.days_off_in_row = 0;
      p.hours_summary = 0;
      // add DEFAULT props to each boristo
      for (const i in this.dataPropsCommon) p[i] = this.dataPropsCommon[i];
      // replace DEFAULT props with personal values if any
      for (const pp of this.dataPropsCommonAll)
        if (pp.id_user == p.id) {
          for (const i in pp.props) p[i] = pp.props[i];
        }
    }

    this.dataPropsPersonalDaily = await request("/api/auto/getpropspersonaldaily", "POST", {
      status: "work",
      date_begin: this.date_begin,
      date_end: this.date_end,
    });

    this.dataPropsPersonalWeekly = await request("/api/auto/getpropspersonalweekly", "POST", {
      status: "work",
    });

    // create propsWeekly Representation
    // fill props for each weekday
    // two-way algo:
    // first - fill each man's slot with weekdays and set default props into each weekday
    // second - replace default props by personal for each weekday
    // STRUCTURE: [id_people][Mon][p_daytime:'morning', p_point:'1'...]
    // STRUCTURE: [.........][Tue][p_daytime:'morning', p_point:'1'...]
    // STRUCTURE: [.........][Wed][p_daytime:'morning', p_point:'1'...]
    // through all props

    for (const p of this.dataPropsPersonalWeekly) {
      // init new element of array by nested array
      if (!this.weekRepresent[parseInt(p.id_people)])
        this.weekRepresent[parseInt(p.id_people)] = {};
      // create week array
      for (const w of WEEKDAYS) {
        if (!this.weekRepresent[parseInt(p.id_people)][w])
          this.weekRepresent[parseInt(p.id_people)][w] = {};
      }
      // fill prop FOR EACH WEEKDAY
      if (!p.props.p_weekday) {
        // each prop
        for (const pp in p.props) {
          // for each weekday
          for (const w of WEEKDAYS) {
            // through all props in
            this.weekRepresent[parseInt(p.id_people)][w][pp] = p.props[pp];
          }
        }
      }
    }

    // through all props
    for (const p of this.dataPropsPersonalWeekly) {
      // init new element of array by nested array
      if (!this.weekRepresent[parseInt(p.id_people)])
        this.weekRepresent[parseInt(p.id_people)] = {};
      // create week array
      for (const w of WEEKDAYS) {
        if (!this.weekRepresent[parseInt(p.id_people)][w])
          this.weekRepresent[parseInt(p.id_people)][w] = {};
      }
      // fill prop FOR CERTAIN WEEKDAY
      if (p.props.p_weekday) {
        if (!(p.props.p_weekday instanceof Array)) p.props.p_weekday = [p.props.p_weekday];
        // each prop
        for (const pp in p.props) {
          if (pp == "p_weekday") continue;
          // for each prop
          for (const x of p.props.p_weekday) {
            // through all props in
            this.weekRepresent[parseInt(p.id_people)][x][pp] = p.props[pp];
          }
        }
      }
    }

    // console.log("this.dataPeople", this.dataPeople);
    // console.log("this.weekRepresent", this.weekRepresent);

    // show result at least
    this.flag = 1;

    this.$emit(
      "calculate",
      this.dataPeople,
      this.dataPropsCommon,
      this.dataPropsPersonalDaily,
      this.dataPropsPersonalWeekly
    );
  },

  methods: {
    // return array (or object) of personal corrections for appropriate person
    dataPropsCommonFilter(id) {
      for (const cp of this.dataPropsCommonAll) {
        if (cp.id_user == id) return cp.props;
      }
    },

    dataPropsPersonalDailyFilter(id) {
      return this.dataPropsPersonalDaily.filter((item) => item.id_user == id);
    },

    nickGet(id) {
      for (const p of this.dataPeople) if (p.id == id) return p.nickname;
      return 'Alien found! (похоже, исключён из "автомата", но правила остались)';
    },

    cell(obj, flag) {
      switch (flag) {
        case "rest":
          return obj["p_status"] && obj["p_status"] == "false" ? true : false;
        //break;

        case "p_daytime":
          if (obj["p_daytime"]) {
            return DAYTIME[obj["p_daytime"]];
            // if (obj["p_daytime"] == "evening") return "вечер";
            // if (obj["p_daytime"] == "morning") return "утро";
          }
          return "*";
        //break;

        case "p_point":
          if (obj["p_point"]) {
            let res = "",
              pre = "";
            if (!(obj["p_point"] instanceof Array)) obj["p_point"] = [obj["p_point"]];
            // ! sign filter
            for (const p of obj["p_point"])
              if (p.indexOf("!") != -1) {
                pre = "не ";
                break;
              }
            for (const p of obj["p_point"]) res += POINTS_SHORTLY[p.replace("!", "")] + " ";
            return pre + res;
          }
          return "*";
        //break;
      }
    },

    dateFormatHuman(d, w) {
      return dateFormatHuman(d, w);
    },

    weekRepresentGet(i) {
      return this.weekRepresent[i];
    },

    propsCommonExplain(prop) {
      let res = "";
      switch (prop) {
        case "turns_in_row_max":
          res = "Смен подряд, не более";
          break;
        case "hours_summary_max":
          res = "Часов в периоде, не более";
          break;
        case "turns_in_a_day_max":
          res = "Смен в сутки, не более";
          break;
        case "days_off_in_row_min":
          res = "Выходных подряд, не менее";
          break;
        default:
          res = "Неизвестный параметр";
          break;
      }
      return res;
    },

    propsPersonalExplain(props) {
      let res = "";
      if (props.status) {
        if (props.status == "true") res += "рабочий день";
        if (props.status == "false") res += "выходной";
      }

      if (props.daytime) {
        if (props.daytime.indexOf("!") != -1) res += " НЕ";
        if (props.daytime.indexOf("morning") != -1) res += " утро";
        if (props.daytime.indexOf("evening") != -1) res += " вечер";
      }

      if (props.points) {
        let prefix = "";
        if (!(props.points instanceof Array)) props.points = [props.points];
        for (const p of props.points) {
          if (p.indexOf("!") != -1) prefix = " не";
          break;
        }

        for (const p of props.points) {
          let i = parseInt(p.replaceAll("!", ""));
          res += prefix + " " + POINTS_SHORTLY[i];
        }
      }
      return res;
    },
  },
};
</script>
