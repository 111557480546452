<template>
  <label>
    {{ label }}
    <select @change="$emit('change', $event.target.value)" ref="minute" v-model="valueLocal">
      <template v-for="el in MINUTES">
        <option :value="el" :key="el">
          <!-- {{ el + parseInt(offset) == 24 ? "00" : el + parseInt(offset) }} -->
          <!-- {{ offsetApply(el + parseInt(offset)) }} -->
          {{ el ? el : "00" }}
        </option>
      </template>
    </select>
  </label>
</template>
  
  <script>
import { MINUTES } from "@/config/settings";
// import { timeFormat, offsetApply } from "@/components-js/timeOffset";
export default {
  name: "MinuteSelector",
  props: { label: String, value: [String, Number] /*, offset: Number */ },
  watch: {
    value: function (newVal) {
      this.valueLocal = newVal;
    },
  },
  data: function () {
    return {
      MINUTES,
      valueLocal: null,
    };
  },
  // methods: {
  //   offsetApply(h/*, l*/) {
  //     return (h/*, l*/);
  //   },
  //   timeFormat(time, flag){
  //     return timeFormat(time, flag);
  //   },
  // },
};
</script>