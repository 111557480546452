<template>
  <div>
    <h3 class="center">Начисленная зарплата бариста суммарно или по каждой точке</h3>
    <div class="heap heapR">
      <div class="heap-block">
        <DateSelector label="Дата нач." :val="date_begin" @change="date_begin = $event" />
      </div>
      <div class="heap-block">
        <DateSelector label="Дата кон." :val="date_end" @change="date_end = $event" />
      </div>
      <div class="heap-block">
        <PointSelector
          label="Точка"
          :allPointsFlag="true"
          :id_pointIn="id_point"
          @change="id_point = $event"
        />
      </div>
      <div class="heap-block">
        <div @click.prevent="tableDataRetrieve" class="high pointer">
          <button class="btn" ref="btn">Обновить</button>
        </div>
      </div>
    </div>

    <div v-if="loading">
      <Loader />
    </div>
    <div v-else>
      <div class="reminder smalltext">работает сортировка по столбцам</div>

      <table class="t_table">
        <tr class="noselect">
          <th>№</th>
          <th
            ref="fullname_sort"
            @click="sort('fullname', report, $refs, oFlag)"
            class="pointer"
          >
            ФИО
          </th>
          <th ref="job_sort" @click="sort('job', report, $refs, oFlag)" class="pointer">
            Должность
          </th>
          <th ref="hours_sort" @click="sort('hours', report, $refs, oFlag)" class="pointer">
            Часы
          </th>
          <th ref="rate_sort" @click="sort('rate', report, $refs, oFlag)" class="pointer">
            Ставка
          </th>
          <th ref="summary_sort" @click="sort('summary', report, $refs, oFlag)" class="pointer">
            Итого
          </th>
        </tr>

        <template v-if="this.total > 0">
          <tr v-for="(row, i) in report" :key="i">
            <td>{{ i + 1 }}</td>
            <td style="text-align: left">
              {{ row.fullname }}
            </td>
            <td>{{ row.job }}</td>
            <td style="text-align: right">{{ numFormat(row.hours) }}</td>
            <td>{{ row.rate }}</td>
            <td style="text-align: right">{{ numFormat(row.summary) }}</td>
          </tr>
          <tr>
            <td colspan="5"></td>
            <td>
              <b>{{ numFormat(this.total) }}</b>
            </td>
          </tr>
        </template>

        <tr v-else class="t_table">
          <td colspan="300">нет данных по выбранным параметрам</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import DateSelector from "@/components/DateSelector.vue";
import PointSelector from "@/components/PointSelector.vue";
import Loader from "@/components/Loader.vue";
import { dateFormatJS } from "@/components-js/dateFormat";
import { DATE_MAX_INTERVAL } from "@/config/settings";
import { request } from "@/components-js/requestSrv";
import { sortCol } from "@/components-js/sortCol.js";
import { datesCheck } from "@/components-js/datesCheck.js";
import { numFormat } from "@/components-js/numberFormat";
import { datesHalfMonth } from "@/components-js/datesHalfMonth.js";
import { lS } from "@/components-js/localStorage";
const loStorage = new lS();

export default {
  components: { DateSelector, PointSelector, Loader },

  data() {
    return {
      loading: false,
      date_begin: null,
      date_end: null,
      DATE_MAX_INTERVAL,
      id_point: 0,
      report: [],
      total: 0,
      oFlag: 1,
    };
  },

  async mounted() {
    // set default values for dates
    const res = datesHalfMonth();
    this.date_begin = dateFormatJS(res.date_begin);
    this.date_end = dateFormatJS(res.date_end);

    let tmp = loStorage.getObjectProp(this.$route.path, "date_begin");
    if (tmp) this.date_begin = tmp;

    tmp = loStorage.getObjectProp(this.$route.path, "date_end");
    if (tmp) this.date_end = tmp;

    tmp = loStorage.getObjectProp(this.$route.path, "id_point");
    if (tmp) this.id_point = tmp;
    else this.id_point = "%";

    this.tableDataRetrieve();
  },

  methods: {
    async tableDataRetrieve() {
      this.loading = true;

      const res = datesCheck(this.date_begin, this.date_end, DATE_MAX_INTERVAL);
      this.date_begin = dateFormatJS(res.date_begin);
      this.date_end = dateFormatJS(res.date_end);

      // DB request
      const data = await request(
        "/api/report/salary_per_point",
        "POST",
        {
          id_point: this.id_point,
          date_begin: this.date_begin,
          date_end: this.date_end,
        },
        this.$route.path
      );
      this.loading = false;

      this.total = 0;
      for (let i in data) this.total += parseInt(data[i].summary);

      this.report = data;
    },

    sort(col, arr, refs, oFlag) {
      this.oFlag = sortCol(col, arr, refs, oFlag);
    },

    numFormat(x) {
      return numFormat(x);
    },
  },
};
</script>
