<template>
  <div>
    <h3
      v-if="
        this.$root.user.roles.includes('admin') || this.$root.user.roles.includes('pointadmin')
      "
      class="center"
    >
      Смены сотрудника списком
    </h3>
    <h3 v-else class="center">Мои смены</h3>
    <div class="heap heapR">
      <div class="heap-block">
        <DateSelector label="Дата нач." :val="date_begin" @change="date_begin = $event" />
      </div>
      <div class="heap-block">
        <DateSelector label="Дата кон." :val="date_end" @change="date_end = $event" />
      </div>
      <div class="heap-block">
        <PeopleSelector
          v-if="peopleFlag"
          label="Сотрудник"
          filter="work"
          :id_peopleIn="id_people"
          @change="id_people = $event"
        />
      </div>
      <div class="heap-block">
        <div @click.prevent="tableDataRetrieve" class="high pointer">
          <button class="btn" ref="btn">Обновить</button>
        </div>
      </div>
    </div>

    <div v-if="$root.user.roles.includes('admin')" class="flexR right reminder">
      <input type="checkbox" v-model="columnSetup" />
      &nbsp;Настр.столбц.
    </div>
    <div v-else>&nbsp;</div>

    <div v-show="columnSetup" style="padding-bottom: 1rem">
      <div v-for="(x, i) of columnList" :key="i" class="flexR margins1">
        <input type="checkbox" v-model="columnSelected" :value="x" />
        <div class="vertical-list02">&nbsp;{{ columnListCaption[i] }}</div>
      </div>
    </div>

    <div v-if="loading">
      <Loader />
    </div>

    <div v-else>
      <table class="t_table">
        <tr>
          <th v-show="visibleCheck('date')">Дата</th>
          <th v-show="visibleCheck('point')">Точка</th>
          <th v-show="visibleCheck('turn')">Cмена</th>
          <th v-show="visibleCheck('rate')">Ставка</th>
          <th v-show="visibleCheck('hour')">Часов</th>
        </tr>

        <template v-if="this.total > 0">
          <tr v-for="(row, y) in report" :key="y">
            <td v-show="visibleCheck('date')" class="center">
              {{ dateFormatHuman(row.turn_date, "w") }}
            </td>
            <td
              v-show="visibleCheck('point')"
              @click="toChessTable(row.turn_date, row.id_point)"
              class="hover"
            >
              {{ row.point }}
              <br />
              <div class="grey">{{ row.coworkers }}</div>
            </td>
            <td v-show="visibleCheck('turn')">
              {{ offsetApply(row.time_begin) }} &mdash;
              {{ offsetApply(row.time_end) }}
              {{ !row.turn_order ? "🌝" : "\u00A0\u00A0\u00A0\u00A0" }}
            </td>
            <td v-show="visibleCheck('rate')">
              {{ row.turn_rate }}
            </td>
            <td v-show="visibleCheck('hour')">
              {{ row.hours }} {{ row.hours_add ? `(+${row.hours_add})` : null }}
            </td>
          </tr>

          <tr>
            <td v-show="visibleCheck('hour')" :colspan="columnSelected.length - 1"></td>
            <td v-show="visibleCheck('hour')">
              <b>{{ this.total }}</b>
            </td>
          </tr>
        </template>

        <template v-else>
          <tr>
            <td :colspan="columnSelected.length">нет данных по выбранным параметрам</td>
          </tr>
        </template>
      </table>
    </div>
  </div>
</template>

<style scoped>
.hover:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>

<script>
import DateSelector from "@/components/DateSelector.vue";
import PeopleSelector from "@/components/PeopleSelector.vue";
import Loader from "@/components/Loader.vue";
import { dateFormatJS, dateFormatHuman } from "@/components-js/dateFormat";
import { DATE_MAX_INTERVAL } from "@/config/settings";
import { request } from "@/components-js/requestSrv";
import { datesCheck } from "@/components-js/datesCheck.js";
import { lS } from "@/components-js/localStorage";
const loStorage = new lS();
import { timeFormat, offsetApply } from "@/components-js/timeOffset";

export default {
  components: { DateSelector, PeopleSelector, Loader },
  data() {
    return {
      loading: false,
      date_begin: null,
      date_end: null,
      id_people: null,
      report: [],
      coworkers: [],
      total: 0,
      // show/hide people selector for admin/user
      peopleFlag: false,
      // all columns to be shown
      columnList: [],
      // all columns captions
      columnListCaption: [],
      // columns selected to show
      columnSelected: [],
      // selection list show/hide
      columnSetup: false,
    };
  },

  watch: {
    columnSelected: function () {
      loStorage.saveObject(this.$route.path, {
        id_people: this.id_people,
        date_begin: this.date_begin,
        date_end: this.date_end,
        columnSelected: this.columnSelected,
      });
    },
  },

  mounted() {
    // set default values for dates and point
    const d = new Date();

    this.date_begin = this.date_end = dateFormatJS(d);

    let tmp = loStorage.getObjectProp(this.$route.path, "date_begin");
    if (tmp) this.date_begin = tmp;

    tmp = loStorage.getObjectProp(this.$route.path, "date_end");
    if (tmp) this.date_end = tmp;

    // admin and user difference
    if (
      this.$root.user.roles.includes("admin") ||
      this.$root.user.roles.includes("pointadmin")
    ) {
      // show people selector for admin
      this.peopleFlag = true;
      // get id_people from localStorage
      tmp = loStorage.getObjectProp(this.$route.path, "id_people");
      if (tmp) this.id_people = tmp;
    } else {
      // user always see own table
      this.id_people = this.$root.user.uid;
    }

    tmp = loStorage.getObjectProp(this.$route.path, "columnSelected");
    if (this.$root.user.roles.includes("admin") && tmp) {
      for (let el of tmp) this.columnSelected.push(el);
    }
    // retreive data anyway
    this.tableDataRetrieve();
  },

  methods: {
    toChessTable(date, point) {
      this.$router.push({ name: "Chesstable", params: { date: date, id_point: point } });
    },

    offsetApply(h /*, l*/) {
      return offsetApply(h /*, l*/);
    },

    timeFormat(time, flag) {
      return timeFormat(time, flag);
    },

    visibleCheck(col) {
      return this.columnSelected.includes(col);
    },

    dateFormatHuman(d, w) {
      return dateFormatHuman(d, w);
    },

    async tableDataRetrieve() {
      this.loading = true;

      const res = datesCheck(this.date_begin, this.date_end, DATE_MAX_INTERVAL);
      this.date_begin = dateFormatJS(res.date_begin);
      this.date_end = dateFormatJS(res.date_end);

      const data = await request(
        "/api/report/personal_turns",
        "POST",
        {
          id_people: this.id_people,
          date_begin: this.date_begin,
          date_end: this.date_end,
          columnSelected: this.columnSelected,
        },
        this.$route.path
      );

      this.total = 0;
      for (let i in data) this.total += parseInt(data[i].hours);

      if (!this.total) this.total = -1;
      else {
        // if turns found, get coworkers
        this.coworkers = await request("/api/report/personal_turns_coworkers", "POST", {
          id_people: this.id_people,
          date_begin: this.date_begin,
          date_end: this.date_end,
        });

        // through each turn from data find coworkers
        let list = [];
        // !!! change in --> of
        // !!! data[i] --> i
        for (let trn of data) {
          list = this.coworkers.filter(
            (el) =>
              trn.turn_date == el.turn_date &&
              trn.id_point == el.id_point &&
              ((trn.utime_begin >= el.utime_begin && trn.utime_begin < el.utime_end) ||
                (trn.utime_end > el.utime_begin && trn.utime_end <= el.utime_end) ||
                (el.utime_begin > trn.utime_begin && el.utime_end < trn.utime_end) ||
                (trn.utime_begin == el.utime_begin && trn.utime_end == el.utime_end))
            // ((el.utime_begin <= trn.utime_begin && el.utime_end > trn.utime_begin) ||
            //   (el.utime_begin < trn.utime_end && el.utime_end >= trn.utime_end) ||
            //   (el.utime_begin <= trn.utime_begin && el.utime_end >= trn.utime_end))
          );
          if (list.length) {
            // array of coworkers per turn
            trn.coworkers = "";
            for (let l in list.sort((a, b) => a.utime_end - b.utime_end)) // order y time_end desc
              trn.coworkers +=
                (list[l].coworker_nickname
                  ? list[l].coworker_nickname + list[l].job_icon
                  : list[l].coworker_name + list[l].job_icon) + ", ";
          }
        }
      }
      this.report = data;

      // default column selection
      if (!this.columnSelected.length) {
        this.columnSelected.push("date");
        this.columnSelected.push("point");
        this.columnSelected.push("turn");
        this.columnSelected.push("hour");
      }

      this.columnList = [];
      this.columnListCaption = [];

      this.columnList.push("date");
      this.columnListCaption.push("Дата");
      this.columnList.push("point");
      this.columnListCaption.push("Точка");
      this.columnList.push("turn");
      this.columnListCaption.push("Смена");
      this.columnList.push("rate");
      this.columnListCaption.push("Ставка");
      this.columnList.push("hour");
      this.columnListCaption.push("Часы");

      this.loading = false;
    },
  },
};
</script>
