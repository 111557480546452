<template>
  <div class="high" style="display: inline-grid" @click="$refs.point.focus()">
    {{ label }}
    <select
      @change="$emit('change', $event.target.value)"
      ref="point"
      v-model="pointCurrent"
      :disabled="disabled"
    >
      <option v-if="allPointsFlag" value="%" :key="-1">Все точки</option>
      <option v-for="(el, i) in pointList" :value="el.id" :key="i">
        {{ el.nickname }}
      </option>
    </select>
  </div>
</template>

<script>
import { request } from "@/components-js/requestSrv";

export default {
  name: "PointSelector",
  props: {
    label: String,
    id_pointIn: [Number, String],
    allPointsFlag: Boolean,
    disabled: String,
  },

  data: function () {
    return { pointList: null, pointCurrent: null };
  },

  async beforeMount() {
    this.pointList = await request("/api/point/list", "GET");
    if (!this.id_pointIn && this.pointList.length) {
      this.pointCurrent = this.pointList[0].id;
      this.$emit("change", this.pointCurrent);
    }
  },

  watch: {
    id_pointIn: function (newVal) {
      this.pointCurrent = newVal;
    },
  },

  // watch: {
  //   id_pointIn: function (newVal) {
  //     this.$refs.point.value = newVal;
  //   },
  // },

  updated() {
    // when element fully loaded from server
    this.$refs.point.value = this.id_pointIn;
  },
};
</script>
