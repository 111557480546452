<template>
  <div>
    <h3 class="center">Начисленная зарплата бариста с разбивкой по точкам (часы/деньги)</h3>
    <div class="heap heapR">
      <div class="heap-block">
        <DateSelector label="Дата нач." :val="date_begin" @change="date_begin = $event" />
      </div>
      <div class="heap-block">
        <DateSelector label="Дата кон." :val="date_end" @change="date_end = $event" />
      </div>
      <div class="heap-block high" @click.stop="switchUnit()">
        Единица отчётности
        <div class="border-round pointer noselect">{{ unit[unit_counter] }}</div>
      </div>
      <div class="heap-block">
        <div @click.prevent="tableDataRetrieve" class="high pointer">
          <button class="btn" ref="btn">Обновить</button>
        </div>
      </div>
    </div>

    <div v-if="loading">
      <Loader />
    </div>
    <div v-else>
      <div class="reminder smalltext">работает сортировка по столбцам</div>

      <table class="t_table">
        <tr class="noselect">
          <th>№</th>
          <th
            ref="fullname_sort"
            @click="sort('fullname', report, $refs, oFlag)"
            class="pointer"
          >
            ФИО
          </th>
          <template v-for="(el, i) in pointsReport">
            <th style="word-break: break-all" :key="el">{{ pointsNicknames[i] }}</th>
          </template>
          <th ref="summary_sort" @click="sort('summary', report, $refs, oFlag)" class="pointer">
            Итого
          </th>
        </tr>

        <template v-if="this.total > 0">
          <tr v-for="(row, i) in report" :key="i">
            <td>{{ i + 1 }}</td>
            <td style="text-align: left; white-space: nowrap">
              {{ row.fullname }}
            </td>
            <template v-for="(p, j) in pointsReport">
              <td :key="p">{{ numFormat(row[j]) }}</td>
            </template>
            <td>{{ numFormat(row.summary) }}</td>
          </tr>

          <tr>
            <td :colspan="pointsReport.length + 2" style="text-align: left; padding-left: 8%">
              Итого
            </td>
            <td>
              <b>{{ numFormat(this.total) }}</b>
            </td>
          </tr>
        </template>

        <tr v-else>
          <td colspan="300">нет данных по выбранным параметрам</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { numFormat } from "@/components-js/numberFormat";
import DateSelector from "@/components/DateSelector.vue";
import Loader from "@/components/Loader.vue";
import { dateFormatJS } from "@/components-js/dateFormat";
import { DATE_MAX_INTERVAL } from "@/config/settings";
import { request } from "@/components-js/requestSrv";
import { sortCol } from "@/components-js/sortCol.js";
import { datesCheck } from "@/components-js/datesCheck.js";
import { datesHalfMonth } from "@/components-js/datesHalfMonth.js";
import { lS } from "@/components-js/localStorage";
const loStorage = new lS();

export default {
  components: { DateSelector, Loader },

  data() {
    return {
      loading: false,
      date_begin: null,
      date_end: null,
      DATE_MAX_INTERVAL,
      id_point: 0,
      report: [],
      total: 0,
      oFlag: 1,
      pointsNicknames: [],
      pointsReport: [],
      peopleReport: [],
      unit: ["часы", "рубли"],
      unit_counter: 0,
      data: [],
    };
  },

  async mounted() {
    // set default values for dates
    const res = datesHalfMonth();
    this.date_begin = dateFormatJS(res.date_begin);
    this.date_end = dateFormatJS(res.date_end);

    let tmp = loStorage.getObjectProp(this.$route.path, "date_begin");
    if (tmp) this.date_begin = tmp;

    tmp = loStorage.getObjectProp(this.$route.path, "date_end");
    if (tmp) this.date_end = tmp;

    this.tableDataRetrieve();
  },

  methods: {
    numFormat(n) {
      return numFormat(n);
    },

    switchUnit() {
      this.unit_counter++;
      if (this.unit_counter == this.unit.length) this.unit_counter = 0;
      this.reportDraw();
    },

    sort(col, arr, refs, oFlag) {
      this.oFlag = sortCol(col, arr, refs, oFlag);
    },

    async tableDataRetrieve() {
      this.loading = true;

      const res = datesCheck(this.date_begin, this.date_end, DATE_MAX_INTERVAL);
      this.date_begin = dateFormatJS(res.date_begin);
      this.date_end = dateFormatJS(res.date_end);

      // DB request
      this.data = await request(
        "/api/report/salarybypandp",
        "POST",
        {
          date_begin: this.date_begin,
          date_end: this.date_end,
        },
        this.$route.path
      );
      this.loading = false;

      // reorganize dataset to work array

      // get points
      this.pointsReport = [];
      this.pointsNicknames = [];
      this.data.forEach((el) => {
        if (!this.pointsReport.includes(el.id_point)) {
          this.pointsReport.push(el.id_point);
          this.pointsNicknames.push(el.nickname);
        }
      });

      // get people

      this.data.forEach((el) =>
        this.peopleReport.includes(el.id_people) ? null : this.peopleReport.push(el.id_people)
      );
      this.reportDraw();
    },

    reportDraw() {
      // reset and fill final report
      let peopleCurrent = -1;
      let n = -1;

      this.total = 0;
      let temp = [];
      // form result array
      for (const el of this.data) {
        if (peopleCurrent != el.id_people) {
          peopleCurrent = el.id_people;
          n++;
          temp.push([]);
          temp[n].id_people = el.id_people;
          temp[n].summary = 0;
        }

        temp[n]["fullname"] = el.name;

        switch (this.unit_counter) {
          case this.unit.indexOf("рубли"):
            temp[n][this.pointsReport.indexOf(el.id_point)] = el.money;
            temp[n].summary += Number.parseInt(el.money);
            this.total += Number.parseInt(el.money);
            break;
          case this.unit.indexOf("часы"):
            temp[n][this.pointsReport.indexOf(el.id_point)] = el.hours;
            temp[n].summary += Number.parseInt(el.hours);
            this.total += Number.parseInt(el.hours);
            break;
        }
      }

      this.report = temp;
    },
  },
};
</script>
