<template>
  <div v-if="showFlag">
    <section class="modalTurn">
      <form ref="frm" id="frm">
        <div class="modalTurn-content">
          <div class="modalWin">
            <h3 v-if="person">Карточка сотрудника</h3>
            <h3 v-else>Добавить сотрудника</h3>
            <table style="width: 100%">
              <tr>
                <td class="R">Ник</td>
                <td class="L">
                  <input
                    class="width70"
                    type="text"
                    v-model.trim="person.nickname"
                    ref="nickname"
                  />
                </td>
              </tr>
              <tr>
                <td class="R">Статус</td>
                <td class="L">
                  <StatusSelector
                    label=""
                    labelPosition="left"
                    :statusProp="person.status"
                    @change="person.status = $event"
                  />
                </td>
              </tr>
              <tr>
                <td class="R">Должность</td>
                <td class="L">
                  <JobSelector
                    label=""
                    labelPosition="left"
                    :id_job="person.id_job"
                    @change="
                      person.id_job = $event
                      /*jcheckForBeginner();*/
                    "
                  />
                </td>
              </tr>
              <tr>
                <td class="R">Должность доп.</td>
                <td class="L">
                  <input class="width70" type="text" v-model.trim="person.job_extra" />
                </td>
              </tr>
              <tr>
                <td class="R">Авторасписание</td>
                <td class="L">
                  <input type="checkbox" v-model="person.auto_table" class="middle" />
                </td>
              </tr>
              <tr>
                <td colspan="300" style="padding: 0.5rem 0"><hr /></td>
              </tr>
              <tr>
                <td class="R">Фамилия</td>
                <td class="L">
                  <input
                    class="width70"
                    type="text"
                    v-model.trim="person.surname"
                    ref="surname"
                  />
                </td>
              </tr>
              <tr>
                <td class="R">Имя</td>
                <td class="L">
                  <input class="width70" type="text" v-model.trim="person.name" ref="name" />
                </td>
              </tr>
              <tr>
                <td class="R">Отчество</td>
                <td class="L">
                  <input class="width70" type="text" v-model.trim="person.patronymic" />
                </td>
              </tr>
              <tr>
                <td class="R">Пол</td>
                <td class="L">
                  <GenderSelector
                    label=""
                    labelPosition="left"
                    :gender="person.gender"
                    @change="person.gender = $event"
                  />
                </td>
              </tr>
              <tr>
                <td class="R">Курение</td>
                <td class="L">
                  <SmokeSelector
                    labelPosition="left"
                    :status="person.smoke_status"
                    @change="person.smoke_status = $event"
                  />
                </td>
              </tr>
              <tr>
                <td class="R">ДР</td>
                <td class="L">
                  <DateSelector
                    class="width70"
                    label=""
                    labelPosition="left"
                    :val="person.birth"
                    @change="person.birth = $event"
                  />
                </td>
              </tr>
              <tr>
                <td class="R">Телефон:</td>
                <td class="L">
                  <input class="width70" type="text" v-model="person.phone" ref="phone" />
                </td>
              </tr>
              <tr>
                <td class="R">
                  <a
                    :href="`${TELEGRAM_URL_PREFIX}${person.telegram}`"
                    class="telegram"
                    target="_blank"
                  >
                    Telegram:
                  </a>
                </td>
                <td class="L">
                  <input class="width70" type="text" v-model.trim="person.telegram" />
                </td>
              </tr>
              <tr>
                <td class="R">Дата прихода</td>
                <td class="L">
                  <DateSelector
                    class="width70"
                    label=""
                    labelPosition="left"
                    :val="person.date_hire"
                    @change="person.date_hire = $event"
                  />
                </td>
              </tr>
              <tr>
                <td class="R">Банк</td>
                <td class="L" ref="bank">
                  <BankSelector
                    labelPosition="left"
                    :value="person.bank"
                    @change="person.bank = $event"
                  />
                </td>
              </tr>

              <tr>
                <td colspan="300" style="padding: 0.5rem 0"><hr /></td>
              </tr>

              <tr>
                <td class="R">Дата медосмотра</td>
                <td class="L">
                  <DateSelector
                    class="width70"
                    label=""
                    labelPosition="left"
                    :val="person.date_bodycheck"
                    @change="person.date_bodycheck = $event"
                  />
                </td>
              </tr>
              <tr>
                <td class="R">Окончание мед.книжки</td>
                <td class="L">
                  <DateSelector
                    class="width70"
                    label=""
                    labelPosition="left"
                    :val="person.date_bodycheckdoc"
                    @change="person.date_bodycheckdoc = $event"
                  />
                </td>
              </tr>

              <tr>
                <td colspan="300" style="padding: 0.5rem 0"><hr /></td>
              </tr>

              <tr>
                <td class="R" style="vertical-align: top">Роли:</td>
                <td class="L">
                  <input type="checkbox" v-model="roleUser" class="middle" />
                  User
                  <br />
                  <input type="checkbox" v-model="roleAdmin" class="middle" />
                  Admin
                  <br />
                  <input type="checkbox" v-model="roleHrAdmin" class="middle" />
                  HR Admin
                  <br />
                  <input type="checkbox" v-model="rolePointAdmin" class="middle" />
                  Point Admin
                  <div v-show="rolePointAdmin" class="width70">
                    <PointSelectorMulti
                      :id_user="person.id"
                      @change="person.pointadminlist = $event"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td class="R">Логин</td>
                <td class="L">
                  <input class="width70" type="text" disabled="true" v-model="person.login" />
                </td>
              </tr>
              <tr>
                <td class="R">Пароль</td>
                <td class="L">
                  <input
                    class="width70"
                    type="password"
                    v-model="person.pass"
                    ref="pass"
                    @focus="setType('pass', 'text')"
                    @blur="setType('pass', 'password')"
                  />
                </td>
              </tr>
              <tr>
                <td class="R">Часовой пояс</td>
                <td class="L">
                  {{ 0 > person.timezone_hour ? "-" : "+" }}{{ person.timezone_hour }}
                  {{ person.timezone_min > 0 ? ":" + person.timezone_min : "" }}
                </td>
              </tr>
              <tr>
                <td class="R" colspan="300">
                  Фото
                  <input type="file" name="photo" />
                </td>
              </tr>
              <tr>
                <td colspan="300">
                  <div v-if="person.photo" class="flexR center">
                    <img :src="PHOTO_ROUTE + person.photo" class="small" style="cursor: auto" />
                  </div>
                </td>
              </tr>
            </table>
            <div class="err">{{ errMessage }}&nbsp;</div>
            <br />
            <div class="col-revers">
              <button ref="applyButton" v-on:click.prevent="Apply()" class="btn">
                {{ person.id ? "Применить" : "Добавить" }}
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button v-on:click.prevent="Cancel()" class="btn cancel">Отмена</button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button v-if="person.id" v-on:click.prevent="Delete()" class="btn warn">
                Удалить
              </button>
            </div>
          </div>
        </div>
      </form>
    </section>
  </div>
</template>
<style scoped>
div {
  padding-top: 5px;
  padding-bottom: 5px;
  vertical-align: middle;
}
.col-revers {
  display: flex;
  flex-direction: row-reverse;
}
td.R {
  text-align: right;
}
td.L {
  text-align: left;
}
table {
  border-spacing: 0.4rem;
}
.width70 {
  width: 70%;
}
.middle {
  vertical-align: middle;
}
</style>
<script>
import DateSelector from "@/components/DateSelector.vue";
import JobSelector from "@/components/JobSelector";
import GenderSelector from "@/components/GenderSelector";
import SmokeSelector from "@/components/SmokeSelector";
import BankSelector from "@/components/BankSelector";
import StatusSelector from "@/components/StatusSelector";
import PointSelectorMulti from "@/components/PointSelectorMulti";
import {
  STATUS_VALUE,
  GENDER_VALUE_FULL,
  TIMEZONE_DEFAULT_HOUR,
  TIMEZONE_DEFAULT_MIN,
  TELEGRAM_URL_PREFIX,
  TELEGRAM_NICK_DEFAULT,
} from "@/config/settings";
import { dateFormatJS } from "@/components-js/dateFormat";
import { request } from "@/components-js/requestSrv";
import {
  maskPhoneEngine,
  maskPhoneFormat,
  maskPhoneLogin,
  maskPhoneValidity,
} from "@/components-js/maskPhone";
import { PHOTO_ROUTE } from "../config/settings";
import { HISTORY_EVENT_VALUE } from "@/config/settings";

export default {
  name: "PeopleWindow",
  components: {
    DateSelector,
    JobSelector,
    GenderSelector,
    SmokeSelector,
    BankSelector,
    StatusSelector,
    PointSelectorMulti,
  },
  props: {
    showFlag: Boolean,
    personIn: Object,
  },

  data() {
    return {
      errMessage: null,
      mask: null,
      person: { phone: null, status: null },
      passMinLength: 4,
      roleUser: null,
      roleAdmin: null,
      rolePointAdmin: null,
      roleHrAdmin: null,
      TELEGRAM_URL_PREFIX: TELEGRAM_URL_PREFIX,
      PHOTO_ROUTE: PHOTO_ROUTE,
      photo: String,
    };
  },

  watch: {
    "person.phone": function (newValue) {
      this.person.login = maskPhoneLogin(newValue);
      this.person.phone = maskPhoneFormat(this.person.phone);
      if (this.person.login.length >= this.passMinLength) {
        this.person.pass = "";
        for (let i = 1; i <= this.passMinLength; i++) {
          this.person.pass += this.person.login.charAt(this.person.login.length - i);
        }
      }
    },

    "person.status": function (newValue) {
      if (newValue == "fired") {
        this.person.auto_table = false;
      }
    },

    showFlag: function (newValue) {
      // window appeared
      if (newValue) {
        document.addEventListener("keyup", this.keyPressHandler);
        if (!this.person) this.person = new Object();
        // after end of render
        this.$nextTick(function () {
          maskPhoneEngine(this.$refs.phone);
        });

        if (this.personIn) {
          // create copy
          for (let i in this.personIn) {
            this.person[i] = this.personIn[i];
          }
          // set roles
          this.roleUser = this.person.roles ? this.person.roles.includes("user") : false;
          this.roleAdmin = this.person.roles ? this.person.roles.includes("admin") : false;
          this.roleHrAdmin = this.person.roles ? this.person.roles.includes("hradmin") : false;
          this.rolePointAdmin = this.person.roles
            ? this.person.roles.includes("pointadmin")
            : false;
        } else {
          this.initDefault();
        }
      } else document.removeEventListener("keyup", this.keyPressHandler);
    },
  },

  methods: {
    checkForBeginner() {
      // add 🐥 for bariska beginner
      // if (this.person.id_job == 1) this.person.nickname = "🐥" + this.person.nickname;
      // else this.person.nickname = this.person.nickname.replaceAll("🐥", "");
      // this.$refs.nickname.value = this.person.nickname;
    },

    initDefault() {
      this.person.id = 0;
      this.person.name = "Имя";
      this.person.surname = "Фамилия";
      this.person.patronymic = "Отчество";
      this.person.nickname = "Милаха";
      this.person.birth = "2000-01-01";
      this.person.id_job = 0;
      this.person.phone = "";
      this.person.status = STATUS_VALUE[0];
      this.person.gender = GENDER_VALUE_FULL[0];
      this.person.login = null;
      this.person.pass = null;
      this.person.date_hire = dateFormatJS(new Date());
      this.person.bank = null;
      this.person.telegram = TELEGRAM_NICK_DEFAULT;
      this.person.timezone_hour = TIMEZONE_DEFAULT_HOUR;
      this.person.timezone_min = TIMEZONE_DEFAULT_MIN;
      this.person.auto_table = true;
      this.person.smoke_status = "none";
      this.person.pointadminlist = [];
      this.person.photo = "";
      this.person.job_extra = "";
      this.person.date_bodycheck = null;
      this.person.date_bodycheckdoc = null;

      this.roleUser = true;
      this.roleAdmin = false;
      this.roleHrAdmin = false;
      this.rolePointAdmin = false;
    },

    setType(ref, type) {
      this.$refs[ref].setAttribute("type", type);
    },

    keyPressHandler(evt) {
      if (evt.code == "Escape") this.Cancel();
    },

    async Apply() {
      if (!this.person.surname.replaceAll(" ", "").length) {
        this.$refs.surname.classList.add("warn-border");
        setTimeout(() => this.$refs.surname.classList.remove("warn-border"), 300);
        return;
      }

      if (!this.person.name.replaceAll(" ", "").length) {
        this.$refs.name.classList.add("warn-border");
        setTimeout(() => this.$refs.name.classList.remove("warn-border"), 300);
        return;
      }

      if (!maskPhoneValidity(this.person.phone)) {
        this.$refs.phone.classList.add("warn-border");
        setTimeout(() => this.$refs.phone.classList.remove("warn-border"), 300);
        return;
      }

      if (!this.person.bank) {
        this.$refs.bank.classList.add("warn-border");
        setTimeout(() => this.$refs.bank.classList.remove("warn-border"), 300);
        return;
      }

      if (
        !this.person.pass ||
        this.person.pass.replaceAll(" ", "").length < this.passMinLength
      ) {
        this.$refs.pass.classList.add("warn-border");
        setTimeout(() => this.$refs.pass.classList.remove("warn-border"), 300);
        this.errMessage = `Пароль не менее ${this.passMinLength} символов.`;
        setTimeout(() => (this.errMessage = null), 1500);
        return;
      }

      this.$refs.applyButton.disabled = true;

      const roles = [];
      if (this.roleUser) roles.push("user");
      if (this.roleAdmin) roles.push("admin");
      if (this.roleHrAdmin) roles.push("hradmin");
      if (this.rolePointAdmin) roles.push("pointadmin");
      this.person.roles = roles;

      // fill form data manually, because not all elements are input with name attribute
      const frmData = new FormData(this.$refs.frm);
      frmData.append("id", this.person.id);
      frmData.append("surname", this.person.surname);
      frmData.append("name", this.person.name);
      frmData.append("patronymic", this.person.patronymic);
      frmData.append("nickname", this.person.nickname);
      frmData.append("birth", this.person.birth);
      frmData.append("id_job", this.person.id_job);
      frmData.append("phone", this.person.phone);
      frmData.append("status", this.person.status);
      frmData.append("gender", this.person.gender);
      frmData.append("date_hire", this.person.date_hire);
      frmData.append("bank", this.person.bank);
      frmData.append("telegram", this.person.telegram);
      frmData.append("login", this.person.login);
      frmData.append("pass", this.person.pass);
      frmData.append("roles", JSON.stringify(this.person.roles));
      frmData.append("pointadminlist", this.person.pointadminlist);
      frmData.append("timezone_hour", this.person.timezone_hour);
      frmData.append("timezone_min", this.person.timezone_min);
      frmData.append("auto_table", this.person.auto_table ? 1 : 0);
      frmData.append("smoke_status", this.person.smoke_status);
      frmData.append("photoPrev", this.person.photo ? this.person.photo : "");
      frmData.append("job_extra", this.person.job_extra);
      if (this.person.date_bodycheck)
        //null value emulation
        frmData.append("date_bodycheck", this.person.date_bodycheck);
      if (this.person.date_bodycheckdoc)
        //null value emulation
        frmData.append("date_bodycheckdoc", this.person.date_bodycheckdoc);

      if (this.person.id) {
        // unique login check
        if (!(await this.checkUnique(this.person.id, this.person.login))) return;
        const res = await request("/api/user/update", "POST", frmData, null, true);
        if (res.affectedRows) this.windowClose();
        else {
          this.$refs.applyButton.classList.add("warn");
          setTimeout(() => this.$refs.applyButton.classList.remove("warn"), 300);
          return;
        }
      } else {
        // unique login check
        if (!(await this.checkUnique(this.person.id, this.person.login))) return;
        const res = await request("/api/user/insert", "POST", frmData, null, true);
        if (res.affectedRows == 1 && res.insertId) {
          this.person.id = res.insertId;
          // add first achivement of new employer :)
          const item = {};
          item.id = null;
          item.id_people = this.person.id;
          item.date = dateFormatJS(new Date());
          item.event = HISTORY_EVENT_VALUE[4];
          item.comment = "Встал на путь бариски";
          item.id_trainer = null;
          item.id_attestator0 = null;
          item.id_attestator1 = null;
          item.id_attestator2 = null;
          item.attestation_type = null;

          await request("/api/history/insert", "POST", item);
          this.windowClose();
        }
      }
    },

    async checkUnique(id, login) {
      const check = await request("/api/user/checkunique", "POST", {
        id: id,
        login: login,
      });
      if (check[0].cnt) {
        this.errMessage = "Логин уже используется";
        setTimeout(() => (this.errMessage = null), 1500);
        return false;
      }
      return true;
    },

    async Delete() {
      if (!confirm("Удалить пользователя?")) return;
      let res = await request("/api/user/deletecheck", "POST", { id: this.person.id });
      if (res[0].cnt) {
        this.errMessage = res[0].cnt + " смен с участием персоны";
        setTimeout(() => (this.errMessage = null), 1500);
        return;
      }
      res = await request("/api/user/delete", "DELETE", {
        id: this.person.id,
        photo: this.person.photo,
      });
      if (res.affectedRows) {
        this.person.id *= -1;
        this.windowClose();
      } else {
        this.errMessage = "ошибка удаления";
        setTimeout(() => (this.errMessage = null), 1500);
      }
    },

    Cancel() {
      this.person.id = 0;
      this.windowClose();
    },

    windowClose() {
      this.$emit("hide", this.person);
    },
  },
};
</script>
