<template>
  <div>
    <h3 class="center">Локации</h3>

    <div class="flexR right">
      <input type="checkbox" v-model="columnSetup" />
      &nbsp;Настр.столбц.
    </div>

    <div v-show="columnSetup">
      <div v-for="(x, i) of columnList" :key="i" class="flexR margins1">
        <input type="checkbox" v-model="columnSelected" :value="x" />
        <div class="vertical-list02">&nbsp;{{ columnListCaption[i] }}</div>
      </div>
    </div>

    <div v-if="loading">
      <Loader />
    </div>

    <div v-else>
      <div class="reminder smalltext">работает сортировка по столбцам</div>

      <table class="t_table" style="table-layout: auto">
        <tr class="noselect">
          <th>№</th>
          <template v-for="(col, i) in columnList">
            <th
              class="pointer"
              v-show="columnSelected.includes(col)"
              :key="i"
              :ref="col + '_sort'"
              @click="sort(col, report, $refs, oFlag)"
            >
              {{ columnListCaption[i] }}
            </th>
          </template>
        </tr>
        <template v-if="rowsCount">
          <tr
            v-for="(row, y) in report"
            :key="y"
            class="point"
            @click="windowPointOpen(row.id)"
          >
            <td>{{ y + 1 }}</td>
            <template v-for="(col, i) in columnList">
              <td v-show="columnSelected.includes(col)" :key="i">
                {{ decorate(row[col]) }}
              </td>
            </template>
          </tr>
        </template>
        <tr v-else>
          <td class="center" colspan="300">нет данных</td>
        </tr>

        <tr class="point">
          <td colspan="300" style="border: none; background-color: white; padding-top: 1rem">
            <div @click="windowPointOpen(0)">добавить локацию</div>
          </td>
        </tr>
      </table>
    </div>

    <PointWindow :showFlag="showFlag" @hide="windowPointClose" :pointIn="point" />
  </div>
</template>

<style scoped>
tr.point:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>

<script>
import PointWindow from "@/components/PointWindow.vue";
import Loader from "@/components/Loader.vue";
import { request } from "@/components-js/requestSrv";
import { sortCol } from "@/components-js/sortCol.js";
import { lS } from "@/components-js/localStorage";
const loStorage = new lS();

export default {
  components: { Loader, PointWindow },
  data() {
    return {
      loading: false,
      report: [],
      rowsCount: 0,
      showFlag: false,
      point: {},
      columnSetup: false,
      columnSelected: [],
      columnList: [],
      columnListCaption: [],
      oFlag: 1,
    };
  },

  watch: {
    columnSelected() {
      loStorage.saveObject(this.$route.path, {
        columnSelected: this.columnSelected,
      });
    },
  },

  mounted() {
    let tmp = loStorage.getObjectProp(this.$route.path, "columnSelected");
    if (tmp) {
      for (let el of tmp) this.columnSelected.push(el);
    }
    this.tableDataRetrieve();
  },

  methods: {
    sort(col, arr, refs, oFlag) {
      this.oFlag = sortCol(col, arr, refs, oFlag);
    },

    windowPointOpen(id) {
      if (id) {
        const pos = this.report.findIndex((el) => el.id == id);
        this.point = this.report[pos];
      } else {
        this.point = null;
      }
      this.showFlag = true;
    },

    async windowPointClose(point) {
      this.showFlag = false;
      if (!point.id) return;
      await this.tableDataRetrieve();
    },

    async tableDataRetrieve() {
      this.loading = true;

      const data = await request("/api/report/pointlist");
      this.report = data;

      this.columnList = [];
      this.columnListCaption = [];

      // default selection
      if (!this.columnSelected.length) {
        this.columnSelected.push("nickname");
        this.columnSelected.push("type");
        this.columnSelected.push("status");
        this.columnSelected.push("date_open");
        this.columnSelected.push("show_on_today");
        this.columnSelected.push("show_on_draft");
      }

      this.columnList.push("nickname");
      this.columnListCaption.push("Название");
      this.columnList.push("address");
      this.columnListCaption.push("Адрес");
      this.columnList.push("type");
      this.columnListCaption.push("Тип");
      this.columnList.push("status");
      this.columnListCaption.push("Статус");
      this.columnList.push("date_open");
      this.columnListCaption.push("Дата открытия");
      this.columnList.push("ikko_link");
      this.columnListCaption.push("Связь с ikko");
      this.columnList.push("sales");
      this.columnListCaption.push("Продажи (чеки)");
      this.columnList.push("salesk");
      this.columnListCaption.push("Продажи (к-т)");
      this.columnList.push("work_time");
      this.columnListCaption.push("Время работы");
      this.columnList.push("show_on_today");
      this.columnListCaption.push("Выводить на 'Сегодня'");
      this.columnList.push("show_on_draft");
      this.columnListCaption.push("Выводить в 'Черновике'");
      this.columnList.push("timezone");
      this.columnListCaption.push("Часовой пояс (от Мск)");
      this.rowsCount = data.length;
      this.loading = false;
    },

    decorate(val) {
      switch (val) {
        case "open":
          return "открыта";
        case "closed":
          return "закрыта";
        case 1:
          return "да";
        case 0:
          return "нет";
        default:
          return val;
      }
    },
  },
};
</script>
