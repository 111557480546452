<template>
  <div class="noselect margins3" style="align-self: start">
    <div>
      <h3 class="pointer" @click="followLink('/moneymovementsheet')">
        Ведомость начислений/выплат
      </h3>
      <h3 class="pointer" @click="followLink('/freeturns-admin')">Свободные смены</h3>
      <h3 class="pointer" @click="followLink('/salaryaverage')">Средняя зарплата</h3>
      <h3 class="pointer" @click="followLink('/personal')">Смены сотрудника списком</h3>
      <h3 class="pointer" @click="followLink('/salary')">
        Начисленная зарплата бариста суммарно или по каждой точке
      </h3>
      <h3 class="pointer" @click="followLink('/salarybypandp')">
        Начисленная зарплата бариста с разбивкой по точкам (часы/деньги)
      </h3>
      <h3 class="pointer" @click="followLink('/summary')">ФОТ на каждую точку</h3>
      <h3 class="pointer" @click="followLink('/salaryhourly')">ЗП бариски по часам</h3>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {};
  },

  methods: {
    followLink(href) {
      this.$router.push(href);
    },
  },
};
</script>
